<template>
    <div class="pa-5">
        <v-col lg="12">
            <Nuevo :dialognuevo="dialognuevo" @dialognuevo="dialognuevo = $event" :Item="seleccionado"
                @instalarneumatico="instalarneumatico" />
            <Retiro :dialogretiro="dialogretiro" @dialogretiro="dialogretiro = $event" :Item="seleccionado"
                @desinstalarneumatico="desinstalarneumatico" />

            <v-toolbar color="secondary" dark>
                <span class="text-h6"> Instalación de Neumáticos</span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://helpdesk.tiresoft.pe/#mant_inf_vehiculos_instalar"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>

            <!--FORMULARIO INSTALACION-->
            <v-row justify="center">
                <v-col lg="12">
                    <v-card class="pa-3">
                        <v-card-subtitle>Datos del vehículo</v-card-subtitle>
                        <v-card-text>
                            <v-row class="pa-2">
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field label="N° de Placa " hide-details="auto"
                                        v-model="ItemInstalacion.placa" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field label="Codigo " hide-details="auto" v-model="ItemInstalacion.codigo"
                                        readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field label="Configuración " hide-details="auto"
                                        v-model="ItemInstalacion.configuracion" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field label="Nº Neumaticos " hide-details="auto"
                                        v-model="ItemInstalacion.numneumaticos" readonly></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field type="date" label="Fecha Instalación / Retiro " hide-details="auto" id="fechainstalacion"
                                        v-model="ItemInstalacion.fechainstalacion" focus @change="onValidarDuplicidad(),onValidarFecha()"
                                        required></v-text-field>
                                        <span class="red--text letra-11">{{ mensajevalidacionfecha }}</span>
                                </v-col>
                                <!--TIPO COSTO DE VEHICULO -->
                                <div hidden>{{ labelKilometrosInstalacion() }}</div>
                                <v-col cols="12" sm="6" md="3">
                                    <v-text-field type="number" :label="labelKM" hide-details="auto"
                                        v-model="ItemInstalacion.kilometraje" @change="onValidarDuplicidad(),onValidarKm()"   required></v-text-field><span class="red--text letra-11">{{ mensajevalidacionkm }}</span>
                                </v-col>
                                <!--TIPO COSTO DE VEHICULO -->
                            </v-row> 
                            <v-card-text v-if="validacioncorrectaduplicidad">
                                <v-alert outlined type="warning" prominent border="left">
                                    {{ mensajevalidacionduplicidad }}
                                </v-alert>
                            </v-card-text>
                            <v-row class="pa-2">
                                <!--ATRAS-->
                                <v-col cols="12" sm="12" md="12">
                                    <v-btn color="error" style="float: right !important;"
                                        @click="cancelarInstalacion()"> Cancelar
                                    </v-btn>
                                </v-col>
                                <!--FIN ATRAS-->
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <!--FORMULARIO INSTALACION-->

            <v-row justify="center">
                <vue-loaders v-if="loader == true" name="ball-beat" color="red" scale="1"></vue-loaders>
            </v-row>

            <v-row justify="center" v-if="mostrarNeumaticos">
                <v-col cols="12" sm="12" md="4">
                    <!--Neumaticos-->
                    <v-card>
                        <v-toolbar height="50" color="#545554" dark> Neumáticos </v-toolbar>
                        <TablaNeumaticos v-if="mostrarTablaInstalacion" @enviarDatos="enviarDatos" :Cabecera="Cabecera"
                            :Items="ItemsNeumaticos" :MostrarImagen="false" />
                    </v-card>
                </v-col>

                <v-col cols="12" sm="12" md="8">
                    <v-card>
                        <v-toolbar height="50" color="#545554" dark>
                            Instalación de neumáticos
                        </v-toolbar>
                        <v-card-text>
                            <v-row justify="center">
                                <div class="table-responsive pa-2">
                                    <table class="table" style="width: 100%">
                                        <tr>
                                            <td></td>
                                            <td v-for="pos in fila_1" :key="pos">
                                                <v-btn width="105" height="45" class="pa-0 ml-2 button"
                                                    v-if="existeposicion(pos, ItemsPosicion)" @click="
                                                        seleccionarposicion(
                                                            pos,
                                                            existeposicion(pos, ItemsPosicion)
                                                        )
                                                        ">
                                                    <Imagen :num_serie="existeposicion(pos, ItemsPosicion).num_serie
                                                        " v-if="
                                existeposicion(pos, ItemsPosicion)
                                    .id_neumaticos != '-'
                            ">
                                                    </Imagen>
                                                    <div v-else>
                                                        {{ 'P'+ItemsPosicion[pos].posicion }}
                                                    </div>
                                                </v-btn>
                                                <div v-else>&nbsp;</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td v-for="pos in fila_2" :key="pos">
                                                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="105" height="45"
                                                    class="pa-0 ml-2 button" @click="
                                                        seleccionarposicion(
                                                            pos,
                                                            existeposicion(pos, ItemsPosicion)
                                                        )
                                                        ">
                                                    <Imagen :num_serie="existeposicion(pos, ItemsPosicion).num_serie
                                                        " v-if="
                                existeposicion(pos, ItemsPosicion)
                                    .id_neumaticos != '-'
                            ">
                                                    </Imagen>

                                                    <div v-else>
                                                        {{ 'P'+ItemsPosicion[pos].posicion }}
                                                    </div>
                                                </v-btn>
                                                <div v-else>&nbsp;</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td v-for="pos in fila_3" :key="pos">
                                                <v-btn v-if="existeposicion(pos, ItemsPosicion)" width="105" height="45"
                                                    class="pa-0 ml-2 button" @click="
                                                        seleccionarposicion(
                                                            pos,
                                                            existeposicion(pos, ItemsPosicion)
                                                        )
                                                        ">
                                                    <Imagen :num_serie="existeposicion(pos, ItemsPosicion).num_serie
                                                        " v-if="
                                existeposicion(pos, ItemsPosicion)
                                    .id_neumaticos != '-'
                            ">
                                                    </Imagen>

                                                    <div v-else :title="existeposicion(pos, ItemsPosicion)">
                                                        {{ 'P'+ItemsPosicion[pos].posicion }}
                                                    </div>
                                                </v-btn>
                                                <div v-else>&nbsp;</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td v-for="pos in fila_4" :key="pos">
                                                <v-btn width="105" height="45" class="pa-0 ml-2 button"
                                                    v-if="existeposicion(pos, ItemsPosicion)" @click="
                                                        seleccionarposicion(
                                                            pos,
                                                            existeposicion(pos, ItemsPosicion)
                                                        )
                                                        ">
                                                    <Imagen :num_serie="existeposicion(pos, ItemsPosicion).num_serie
                                                        " v-if="
                                existeposicion(pos, ItemsPosicion)
                                    .id_neumaticos != '-'
                            ">
                                                    </Imagen>

                                                    <div v-else>
                                                        {{ 'P'+ItemsPosicion[pos].posicion }}
                                                    </div>
                                                </v-btn>
                                                <div v-else>&nbsp;</div>
                                            </td>
                                        </tr>
                                        <br />
                                    </table>
                                </div>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <br />
                    <v-col lg="12">
                        <v-btn style="width: 150px !important;" color="success" :disabled="arrayneumaticoposicioninstalar.length == 0 &&
                            arrayneumaticoposiciondesinstalar.length == 0
                            " @click="openModalOpcionesParaGuardar()" :loading="loadingGuardar">Guardar</v-btn>
                    </v-col>
                </v-col>
            </v-row>

            <MensajeTransicion color="success" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

            <!--MODAL INSTALACION-->

            <v-dialog v-model="dialogopcionesguardar" width="auto">
                <v-card>
                    <v-card-actions>
                        <v-btn color="success" @click="guardarInstalacion('guardar')" :loading="loadingGuardar"> Guardar
                        </v-btn>
                        <v-btn color="info" class="ml-2" @click="guardarInstalacion('guardarYsalir')"
                            :loading="loadingGuardarySalir">
                            Guardar y salir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
import TablaNeumaticos from "../components/NeumaticoVerticalTable.vue";
import MensajeTransicion from "../components/Alerta.vue"
import Nuevo from "../components/instalacionneumaticos/nuevo.vue";
import Retiro from "../components/instalacionneumaticos/retiro.vue";
import Imagen from "../components/NeumaticoImageButton.vue";
import router from "@/router";
import global from "../global"

import {
    mapState,
    mapActions
} from "vuex";
import {
    axios2 as axios
} from "@/interceptor/useApi";

export default {
    components: {
        TablaNeumaticos,
        Imagen,
        Nuevo,
        Retiro,
        MensajeTransicion
    },
    data() {
        return {
            labelKM: "",
            ItemsNeumaticos: [],
            //ItemsHistorial: [],
            ItemsPosicion: [],
            Cabecera: [{
                text: "",
                value: "num_serie",
                align: "center",
                width: "100",
            },],
            limite: 0,
            TituloModal: "",
            dialognuevo: false,
            dialogretiro: false,
            seleccionado: {
                km_instalacion: null,
                fecha_instalacion: null,
            },
            neumaticoSelected: null,
            posicionSeleccionada: null,
            arrayneumaticoposicioninstalar: [],
            arrayneumaticoposiciondesinstalar: [],
            secuenciados: [{
                posicion: 2,
                text: 2,
            },],
            secuenciauno: [{
                posicion: 1,
                text: 1,
            },],
            fila_1: [6, 10, 14, 18, 22],
            fila_2: [2, 4, 8, 12, 16, 20],
            fila_3: [1, 3, 7, 11, 15, 19],
            fila_4: [5, 9, 13, 17, 21],
            mostrarNeumaticos: false,
            mostrarTablaInstalacion: true,
            pos_inicial: null,
            loader: false,
            dialogopcionesguardar: false,
            loadingGuardar: false,
            loadingGuardarySalir: false,
            mensaje: "",
            verAlerta: false,
            validacioncorrectafecha: false,
            validacioncorrectakm: false,
            validacioncorrectaduplicidad: false,
            mensajevalidacionduplicidad:false,
            mensajevalidacionfecha: "",
            mensajevalidacionkm: "",
        };
    },
    computed: {
        ...mapState("vehiculo", ["ItemInstalacion"]),
    },
    methods: {
        //...mapActions({actualizarItemNeumatico: "vehiculo/actualizarItemNeumatico"}),

        ...mapActions("inspeccion", [
            "validarFecha",
            "validarKm",
            "validarDuplicidad"
        ]),
        ...mapActions("vehiculo", [
            "cargarDatosInstalacion",
            "actualizarItemNeumatico",
            "guardarDatosInstalacion"
        ]),

        onValidarDuplicidad: async function () { 
            this.ItemInstalacion.fechainstalacion=document.getElementById('fechainstalacion').value; 
            if (
                this.ItemInstalacion.fechainstalacion != null &&
                this.ItemInstalacion.fechainstalacion != "" &&
                this.ItemInstalacion.id != "" &&
                this.ItemInstalacion.kilometraje != "" &&
                this.ItemInstalacion.fechainstalacion != ""
            ) {
                this.$emit("fechavalida", global._validar_fecha(this.ItemInstalacion.fechainstalacion));

                let mensaje = await this.validarDuplicidad({
                    'id_vehiculo':this.ItemInstalacion.id,
                    'km_inspeccion':this.ItemInstalacion.kilometraje,
                    'fecha_inspeccion':this.ItemInstalacion.fechainstalacion,
                });
                if (mensaje == "") {
                    this.mensajevalidacionduplicidad = "";
                    this.validacioncorrectaduplicidad = false;
                } else {
                    this.mensajevalidacionduplicidad = mensaje;
                    this.validacioncorrectaduplicidad = true;
                }
            } else {
                this.mensajevalidacionduplicidad = "";
                this.validacioncorrectaduplicidad = false;
            }
        },

        onValidarFecha: async function () { 
            this.ItemInstalacion.fechainstalacion=document.getElementById('fechainstalacion').value;
            if (
                this.ItemInstalacion.fechainstalacion != null &&
                this.ItemInstalacion.fechainstalacion != ""
            ) {
                this.$emit("fechavalida", global._validar_fecha(this.ItemInstalacion.fechainstalacion));

                console.log(this.ItemInstalacion,'ItemInstalacion'); 
                let mensaje = await this.validarFecha({
                    'id_vehiculo':this.ItemInstalacion.id,
                    'fecha_inspeccion':this.ItemInstalacion.fechainstalacion,
                });
                if (mensaje != "Correcto") {
                    this.mensajevalidacionfecha = mensaje;
                    this.validacioncorrectafecha = false;
                    this.mostrarNeumaticos = false;
                } else {
                    this.mensajevalidacionfecha = "";
                    if (this.validacioncorrectafecha==false) { 
                        this.onCargar();
                    }
                    this.validacioncorrectafecha = true; 
                } 
            } else {
                this.mensajevalidacionfecha = "";
                if (this.validacioncorrectafecha==false) { 
                    this.onCargar();
                }
                this.validacioncorrectafecha = true;
            }
        },

        onValidarKm: async function () {  
            if (this.ItemInstalacion.kilometraje != null && this.ItemInstalacion.kilometraje != "" && !isNaN(parseInt(this.ItemInstalacion.kilometraje))) {
                let mensaje = await this.validarKm({
                    'id_vehiculo':this.ItemInstalacion.id,
                    'km_inspeccion':this.ItemInstalacion.kilometraje,
                });
                if (mensaje != "KM Correcto") {
                    this.mensajevalidacionkm = mensaje;
                    this.validacioncorrectakm = false;
                    this.mostrarNeumaticos = false;
                    
                } else {
                    this.mensajevalidacionkm = "";
                    if (this.validacioncorrectakm==false) { 
                        this.onCargar();
                    }
                    this.validacioncorrectakm = true;
                } 
            } else {
                this.mensajevalidacionkm = "";
                if (this.validacioncorrectakm==false) { 
                    this.onCargar();
                }
                this.validacioncorrectakm = true;
            }
        },

        labelKilometrosInstalacion: function () {
            this.labelKM =
                this.ItemInstalacion.tipo_costo == 1 ?
                    "Kilometraje de Vehículo" :
                    "Horas de Vehículo";
        },

        async onCargar() {
            this.ItemInstalacion.fechainstalacion = global._validar_fecha(this.ItemInstalacion.fechainstalacion);
            if (
                this.ItemInstalacion.fechainstalacion != null &&
                this.ItemInstalacion.kilometraje != null && this.ItemInstalacion.fechainstalacion != '' && this.ItemInstalacion.kilometraje != ''
            ) {
                this.loader = true;
                await this.cargarDatosNeumaticosPosicionHistorial();
                this.loader = false;
                this.mostrarNeumaticos = true;
            }
        },

        existeposicion: function (pos, array) {
            let datos=null;
            if (array[pos].ubicacion==pos && array[pos].posicion!=null) {
                datos =array[pos];
            }
            return datos;
        },

        enviarDatos: function (item) {
            this.neumaticoSelected = {
                ...item,
            };
        },

        seleccionarposicion: function (index, objeto) {
            if (objeto.num_serie != "P" + index) {
                //Retiro de neumatico
                this.posicionSeleccionada = objeto;
                //Si id_historial es null el neumático a retirar no esta registrado en la base de datos
                if (objeto.id_historial != null) {
                    this.seleccionado = {
                        id_neumaticos: objeto.id_neumaticos,
                        id_historial: objeto.id_historial,
                        num_serie: objeto.num_serie,
                        fecha_instalacion: objeto.fecha_instalacion,
                        km_instalacion: objeto.km_instalacion,
                        fecha_retiro: this.ItemInstalacion.fechainstalacion, // retiro neumatico
                        km_retiro: this.ItemInstalacion.kilometraje, // retiro neumatico
                        motivo_retiro: null, // retiro neumatico
                        observacion: null,
                    };
                    this.dialogretiro = true;
                } else {
                    this.seleccionado = {
                        id_neumaticos: objeto.id_neumaticos,
                    };
                    this.desinstalarneumatico(objeto);
                }
            } else {
                //Instalacion de neumatico
                if (this.neumaticoSelected != null) {
                    //Cambiar el valor que muestra el boton
                    this.seleccionado = {
                        id_neumaticos: this.neumaticoSelected.id,
                        id_historial: null,
                        num_serie: this.neumaticoSelected.num_serie,
                        fecha_instalacion: this.ItemInstalacion.fechainstalacion, //instalacion de neumatico
                        km_instalacion: this.ItemInstalacion.kilometraje, //instalacion de neumatico
                        observacion: null,
                    };
                    this.posicionSeleccionada = objeto;
                    this.dialognuevo = true;
                }
            }
        },

        desinstalarneumatico: async function (objeto) {
            //Encontrar si la posicion se encuentra en un objeto del array
            //Si se encuentra, volver a su posicion anterior y volver a habilitar el neumatico
            let posicion = this.arrayneumaticoposicioninstalar.findIndex(
                (el) => el.id_neumaticos == objeto.id_neumaticos
            );

            if (objeto.id_historial != null) {
                this.arrayneumaticoposiciondesinstalar.push(objeto);
                this.posicionSeleccionada.id_neumaticos = "-";
                this.posicionSeleccionada.num_serie =
                    "P" + this.posicionSeleccionada.ubicacion;
                //Con opciones de de desinstlación los neumáticos son deshabilitados
            } else {
                //this.posicionSeleccionada obtiene la referencia del objeto en posicion
                this.posicionSeleccionada.id_neumaticos = "-";
                this.posicionSeleccionada.num_serie = "P" + objeto.ubicacion;
                this.deshabilitarohabilitarneumatico(false);
                this.arrayneumaticoposicioninstalar.splice(posicion, 1);
            }
        },

        deshabilitarohabilitarneumatico: async function (boolean) {
            let index = this.ItemsNeumaticos.findIndex(
                (el) => el.id == this.seleccionado.id_neumaticos
            );
            this.ItemsNeumaticos[index].disabled = boolean;
            this.mostrarTablaInstalacion = false;
            await this.$nextTick();
            this.mostrarTablaInstalacion = true;

            this.neumaticoSelected = null;
        },

        instalarneumatico: function (item) {
            this.deshabilitarohabilitarneumatico(true);
            item.posicion = this.posicionSeleccionada.posicion;
            item.ubicacion = this.posicionSeleccionada.ubicacion;
            item.id_ejes = this.posicionSeleccionada.id_ejes;
            this.arrayneumaticoposicioninstalar.push(item);
            this.posicionSeleccionada.num_serie = item.num_serie;
            this.posicionSeleccionada.id_neumaticos = item.id_neumaticos;
            this.posicionSeleccionada.id_historial = item.id_historial;
            this.$forceUpdate();
        },

        openModalOpcionesParaGuardar: function () {
            this.dialogopcionesguardar = true;
        },

        guardarInstalacion: async function (valor) {
            if (valor == "guardar") {
                this.loadingGuardar = true
            } else {
                this.loadingGuardarySalir = true;
            }

            let datos = {
                id_vehiculo: this.ItemInstalacion.id,
                tipo_costo: this.ItemInstalacion.tipo_costo,
                kilometros: this.ItemInstalacion.kilometraje,
                fecha: this.ItemInstalacion.fechainstalacion,
                neumaticosinstalados: this.arrayneumaticoposicioninstalar,
                neumaticosretirados: this.arrayneumaticoposiciondesinstalar,
            };
            await this.guardarDatosInstalacion(datos).then((res) => {
                this.mensaje = "Neumáticos Retirados/Instalados";
                this.verAlerta = true;
                if (valor == "guardar") {
                    setTimeout(() => {
                        this.verAlerta = false;
                        router.go();
                    }, 4000);
                } else {
                    setTimeout(() => {
                        this.verAlerta = false;
                        router.push("/vehiculos");
                    }, 2000);
                }
            }).catch((error) => {
                this.errorMessage = error.response.data;
                console.error("error!", error);
            });
        },

        cancelarInstalacion: function () {
            this.$router.back();
        },

        cargarDatosNeumaticosPosicionHistorial: async function () {
            try {
                const url = "showDatosNeumaticosPosicionHistorial";
                const datos = {
                    id_cliente: localStorage.getItem("idcliente"),
                    vehiculo: this.ItemInstalacion,
                };
                await axios
                    .post(url, datos)
                    .then((response) => {
                        this.ItemsNeumaticos = response.data.success.resultado.neumaticos;
                        let datos=response.data.success.resultado.posicion;
                        for (let i = 0; i < datos.length; i++) {
                            this.ItemsPosicion[datos[i].ubicacion] = datos[i];
                            
                        } 
                        console.log(this.ItemsPosicion,'ItemsPosicion');
                        //this.ItemsHistorial = response.data.success.resultado.historial
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },
    },
    mounted() {
        let id = location.pathname.substr(1).split("/")[2];
        this.cargarDatosInstalacion(id);
    },
};
</script>

<style scoped>
.button {
    border-radius: 15px;
    width: 105px;
    height: 45px;
    background-size: cover;
}

#table {
    display: block;
    /* important */
    width: 1000px;
    overflow-x: scroll;
}
.letra-11 {
    font-size: 11px;
}
</style> 
