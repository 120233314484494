<template>
    <div>
        <v-form enctype="multipart/form-data" ref="form" v-model="valid">
            <v-card>
                <v-toolbar color="secondary" dark>
                    <span v-if="Item.id == null" class="text-h6">Registro de Inspección</span>
                    <span v-else class="text-h6">Editar Inspección</span>
                    <v-spacer></v-spacer>
                    <span v-if="Item.id != null">ID: {{ Item.id }}</span>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                                <a target="_blank" href="https://helpdesk.tiresoft.pe/#registra_inspeccion"
                                    style="text-decoration:none !important; color: white;">
                                    <v-icon dark>mdi-help-circle</v-icon>
                                </a>
                            </v-btn>
                        </template>
                        <span>¿Necesitas ayuda?</span>
                    </v-tooltip>
                </v-toolbar>

                <!-- <v-card-title>
                <span v-if="Item.id == null" class="text-h6">Registro de Inspección</span>
                <span v-else class="text-h6">Editar Inspección</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{ Item.id }}</span>
            </v-card-title> -->
                <v-card-text>
                    <v-row>
                        <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="text" label="Código de inspección " v-model="Item.codigo"
                                hide-details="auto" maxlength="10" @blur="onCodigoInspeccion"></v-text-field>
                            <span class="red--text letra-11">{{ mensajevalidacioncodigo }}</span>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="text" label="Cliente" v-model="Cliente.razon_social" hide-details="auto"
                                readonly required></v-text-field>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="text" label="RUC" v-model="Cliente.ruc" hide-details="auto" readonly
                                required></v-text-field>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-autocomplete class="mt-5" :items="Vehiculos" @change="onSeleccionarVehiculo"
                                v-model="Item.id_vehiculo" label="Seleccionar vehículo *" dense
                                :rules="[(v) => !!v || 'Vehículo recorrido es requerido']"></v-autocomplete>
                            <span class="black--text" v-if="vehiculo.tipovehiculo != ''">
                                <v-btn class="ml-1" color="info" x-small dark>
                                    {{ vehiculo.tipovehiculo }}
                                </v-btn>
                            </span>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-text-field type="date" id="fecha_inspeccion" label="Fecha de inspección *" v-model="Item.fecha_inspeccion"
                                hide-details="auto" :rules="[(v) => !!v || 'Fecha de inspección es requerido']"
                                 @input="onValidarDuplicidad(),onValidarFecha()" :disabled="Item.id_vehiculo == null"></v-text-field>
                            <span class="red--text letra-11">{{ mensajevalidacionfecha }}</span>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="number" label="Kilometraje/Horas Actual * " v-model="Item.km_inspeccion"
                                hide-details="auto" :rules="[(v) => !!v || 'Km de inspección es requerido']"
                                @change="onValidarDuplicidad(),onValidarKm()" :disabled="Item.id_vehiculo == null"></v-text-field>
                            <span class="red--text letra-11">{{ mensajevalidacionkm }}</span>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                            <v-autocomplete class="mt-5" :items="Plantas" v-model="Item.id_planta"
                                label="Seleccionar Planta *" dense></v-autocomplete>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <span class="black--text"><strong>Unidad tiene llanta de repuesto</strong></span>
                            <v-radio-group inline v-model="Item.repuesto" row>
                                <v-radio label="Si" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <span class="black--text"><strong>Unidad tiene porta llanta</strong></span>
                            <v-radio-group inline v-model="Item.portallantas" row>
                                <v-radio label="Si" :value="1"></v-radio>
                                <v-radio label="No" :value="0"></v-radio>
                            </v-radio-group>
                        </div>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="validacioncorrectaduplicidad">
                    <v-alert outlined type="warning" prominent border="left">
                        {{ mensajevalidacionduplicidad }}
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="Item.id != null"  @click="cerrarModal()">
                        Cerrar
                    </v-btn>
                    <v-btn v-if="Item.id == null" color="primary" @click="openModalOpcionesParaGuardar()"
                        :loading="loadingGuardarInspeccion">
                        Guardar
                    </v-btn>
                    <v-btn v-else color="info" @click="editar()" :loading="loadingGuardarInspeccion"  >
                        Editar
                    </v-btn>
                </v-card-actions>
            </v-card>

            <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta && Item.id != null" :top="true" :bottom="false" />
        </v-form>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import axios from "axios";
import Alerta from "../alertas.vue"
import MensajeTransicion from "../Alerta.vue"
import global from "../../global"

export default {
    props: ["dialog", "Item", "Cliente", "Array_id_inspecciones"],
    components: {
        Alerta,
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            loader: false,
            vehiculo: {
                id_vehiculo: null,
                tipovehiculo: "",
                id_configuracion: null,
                id_tipo: null
            },
            mensajee:'',
            validacioncorrectafecha: false,
            validacioncorrectakm: false,
            validacioncorrectaduplicidad: false,
            mensajevalidacionduplicidad:false,
            mensajevalidacionfecha: "",
            mensajevalidacionkm: "",
            mensajevalidacioncodigo: "",
            dialogopcionesguardar: false,
            mensaje: "",
            verAlerta: false,
            loadingGuardarInspeccion: false,
            loadingGuardarInspeccionySalir: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapState("inspeccion", ["Vehiculos", "Plantas"]),
    },
    methods: {
        ...mapActions("inspeccion", [
            "cargarDatosAdicionales",
            "validarCodigo",
            "validarFecha",
            "validarKm",
            "validarDuplicidad"
        ]),

        onSeleccionarVehiculo: function (id) {
            this.Vehiculos.forEach((element) => {
                if (element.value == id) {
                    this.vehiculo.id_vehiculo = id;
                    this.vehiculo.tipovehiculo = element.tipovehiculo;
                    this.vehiculo.id_configuracion = element.id_configuracion;
                    this.vehiculo.id_tipo = element.id_tipo;
                    this.Item.id_planta = element.id_planta;
                }
            });
            this.validacioncorrectafecha = false;
            this.validacioncorrectakm = false;
            this.mensajevalidacionfecha = "";
            this.mensajevalidacionkm = "";
            this.mensajevalidacioncodigo = "";
            //reiniciar valores
            this.Item.codigo = null;
            this.Item.repuesto = 0;
            this.Item.portallantas = 0;
            this.Item.fecha_inspeccion = null;
            this.Item.km_inspeccion = null;
            this.$refs.form.resetValidation();
            this.$emit("refrescar");
        },

        onCodigoInspeccion: async function () {
            if (this.Item.codigo != null && this.Item.codigo != "") {
                let existe = await this.validarCodigo(this.Item.codigo);
                if (existe == true) {
                    this.mensajevalidacioncodigo = "El código de inspección ingresado ya se encuentra registrado";
                } else {
                    this.mensajevalidacioncodigo = "";
                }
            }
        },

        onValidarDuplicidad: async function () {
            this.Item.fecha_inspeccion=document.getElementById('fecha_inspeccion').value;
            console.log(this.Item,'Items')
            if (
                this.Item.fecha_inspeccion != null &&
                this.Item.fecha_inspeccion != "" &&
                this.Item.id_vehiculo != "" &&
                this.Item.km_inspeccion != "" &&
                this.Item.fecha_inspeccion != ""
            ) {
                this.$emit("fechavalida", global._validar_fecha(this.Item.fecha_inspeccion));

                let mensaje = await this.validarDuplicidad(this.Item);
                if (mensaje == "") {
                    this.mensajevalidacionduplicidad = "";
                    this.validacioncorrectaduplicidad = false;
                } else {
                    this.mensajevalidacionduplicidad = mensaje;
                    this.validacioncorrectaduplicidad = true;
                }
            } else {
                this.mensajevalidacionduplicidad = "";
                this.validacioncorrectaduplicidad = false;
            }
        },


        onValidarFecha: async function () {
            this.Item.fecha_inspeccion=document.getElementById('fecha_inspeccion').value;
            if (
                this.Item.fecha_inspeccion != null &&
                this.Item.fecha_inspeccion != ""
            ) {
                this.$emit("fechavalida", global._validar_fecha(this.Item.fecha_inspeccion));

                let mensaje = await this.validarFecha(this.Item);
                if (mensaje != "Correcto") {
                    this.mensajevalidacionfecha = mensaje;
                    this.validacioncorrectafecha = false;
                } else {
                    this.mensajevalidacionfecha = "";
                    this.validacioncorrectafecha = true;
                }
                console.log(this.validacioncorrectafecha,this.validacioncorrectakm,'fecha');
                if (
                    this.validacioncorrectafecha == true &&
                    this.validacioncorrectakm == true
                ) {
                    let datos = {
                        vehiculo: this.vehiculo,
                        inspeccion: this.Item,
                    };
                    this.$emit("cargarPosiciones", {
                        ...datos
                    });
                }
            } else {
                this.mensajevalidacionfecha = "";
                this.validacioncorrectafecha = false;
            }
        },

        onValidarKm: async function () {  
            if (this.Item.km_inspeccion != null && this.Item.km_inspeccion != "" && !isNaN(parseInt(this.Item.km_inspeccion.trim()))) {
                let mensaje = await this.validarKm(this.Item);
                if (mensaje != "KM Correcto") {
                    this.mensajevalidacionkm = mensaje;
                    this.validacioncorrectakm = false;
                } else {
                    this.mensajevalidacionkm = "";
                    this.validacioncorrectakm = true;
                }

                console.log(this.validacioncorrectafecha,this.validacioncorrectakm,'km');
                if (
                    this.validacioncorrectafecha == true &&
                    this.validacioncorrectakm == true
                ) {
                    let datos = {
                        vehiculo: this.vehiculo,
                        inspeccion: this.Item,
                    };
                    this.$emit("cargarPosiciones", {
                        ...datos,
                    });
                }
            } else {
                this.mensajevalidacionkm = "";
                this.validacioncorrectakm = false;
            }
        },

        openModalOpcionesParaGuardar: async function () {
            //si la validación de fecha y km es correcta abrir modal 
                if (this.$refs.form.validate()) {
                    if (
                        this.validacioncorrectafecha == true &&
                        this.validacioncorrectakm == true
                    ) {
                        if (this.Array_id_inspecciones.length > 0) {
                            this.guardar('guardarYsalir');
                        } else {
                            this.verAlerta = true;
                            this.mensaje = "No se ha registrado detalles en la inspección";
                            setTimeout(() => [(this.verAlerta = false)], 4000);
                        }
                    } else {
                        this.verAlerta = true;
                        this.mensaje = "validación de fecha o kilometraje incorrecta";
                        setTimeout(() => [(this.verAlerta = false)], 4000);
                    }
                } 
        },

        editar: async function () {
            try {
                //si la validación de fecha y km es correcta editar
                if (this.validacioncorrectafecha == true && this.validacioncorrectakm == true) {
                    this.loadingGuardarInspeccion = true;
                    const url = "inspecciones/updateInspectionMinified";
                    const data = {
                        id_cliente: localStorage.getItem("idcliente"),
                        id_usuario: localStorage.getItem("id"),
                        identificador: this.Item.identificador,
                        fecha_inspeccion: this.Item.fecha_inspeccion,
                        vehiculo: this.Item.id_vehiculo,
                        codigo: this.Item.codigo,
                        km_inspeccion: this.Item.km_inspeccion,
                        llantarepuesto: this.Item.repuesto,
                        portallanta: this.Item.portallantas,
                        id_planta: this.Item.id_planta
                    };
 
                        await axios
                            .post(url, data)
                            .then((response) => {
                                this.loadingGuardarInspeccion = false;
                                this.$emit("editarInspeccion", this.Item);
                            })
                            .catch((error) => {
                                this.errorMessage = error.response.data;
                                console.error("error!", error);
                            }); 
                } else {
                    this.verAlerta = true;
                    this.mensaje = "validación de fecha o kilometraje incorrecta";
                    setTimeout(() => [(this.verAlerta = false)], 4000);
                }
            } catch (error) {
                console.log(error);
            }
        },

        guardar: async function (opcion) {
            this.loadingGuardarInspeccion = true;

            try {
                const url = "inspecciones/finishedInspectionSimplified";
                let id_planta = this.Item.id_planta == null ? "" : this.Item.id_planta
                const data = {
                    id_cliente: localStorage.getItem("idcliente"),
                    id_usuario: localStorage.getItem("id"),
                    fecha_inspeccion: this.Item.fecha_inspeccion,
                    vehiculo: this.Item.id_vehiculo,
                    codigo: this.Item.codigo,
                    km_inspeccion: this.Item.km_inspeccion,
                    llantarepuesto: this.Item.repuesto,
                    portallanta: this.Item.portallantas,
                    id_planta: id_planta,
                    inspection_id: this.Array_id_inspecciones
                };
                await axios
                    .post(url, data)
                    .then((response) => {
                        this.loadingGuardarInspeccion = false;
                        this.$emit("guardarInspeccion", opcion);
                    })
                    .catch((error) => {
                        this.errorMessage = error.response.data;
                        console.error("error!", error);
                    });
            } catch (error) {
                console.log(error);
            }
        },

        cerrarModal: function () {
            this.validacioncorrectafecha= true,
            this.validacioncorrectakm= true,
            this.mensajevalidacionfecha= "";
            this.mensajevalidacionkm= "";
            this.mensajevalidacioncodigo= "";
            this.$emit("cerrarModalNuevo");
        }
    },

    watch: {
        // Puedes observar cambios en las props y actualizar el estado si es necesario
        Item() {  
            this.validacioncorrectafecha= true,
            this.validacioncorrectakm= true,
            this.mensajevalidacionfecha= "";
            this.mensajevalidacionkm= "";
            this.mensajevalidacioncodigo= "";
        }
    },
    created() { 
        this.cargarDatosAdicionales();
    },
    mounted() {
        if (this.Item.id != null) { 
            this.validacioncorrectafecha = true;
            this.validacioncorrectakm = true;
        }
    },
};
</script>

<style scoped>
.letra-11 {
    font-size: 11px;
}
</style>
