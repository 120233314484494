<template>
<div class="pa-5">
    <v-col lg="12">
        <v-card>
            <v-card-text>
                <v-alert outlined type="warning" prominent border="left">
                    Ingrese remanente para que la aplicación se muestre en el registro y edición de vehículo, de no encontrar aplicación puede registrarla desde el botón “Nuevo”.
                </v-alert>

            </v-card-text>
        </v-card>
        <tabla Titulo="Lista de remanente por aplicación" :Cabecera="Cabecera" :Items="Items" :ItemsAplicacion="ItemsAplicacion" :Excel="false" :Crear="true" :Loading="Loading" />
    </v-col>
</div>
</template>

<script>
import tabla from "@/components/aplicacion/tabla.vue"

import {
    mapGetters,
    mapActions,
    mapState
} from "vuex";
export default {
    name: "Aplicacion",
    components: {
        tabla
    },
    data() {
        return {
            Cabecera: [{
                    text: 'Opcion',
                    value: 'acciones'
                },
                {
                    text: 'Aplicación',
                    value: 'aplicacion_nom'
                },
                {
                    text: 'Inicio de Reencauche',
                    value: 'ini_reencauche'
                },
                {
                    text: 'Limite de Reencauche',
                    value: 'reencauche'
                },
                {
                    text: 'Inicio de Próximo a Reencauchar',
                    value: 'ini_prox_reencauche'
                },
                {
                    text: 'Limite de Próximo a Reencauchar',
                    value: 'prox_reencauche'
                },
            ]
        }
    },
    computed: {
        ...mapGetters('aplicacion', ['Items','ItemsAplicacion']), 
        ...mapState('aplicacion',['Loading'])
    },
    methods: {
        ...mapActions('aplicacion', ['cargarAplicacion','cargarAplicacionCMB']),
    },
    created() {
        this.cargarAplicacion();
        this.cargarAplicacionCMB();
    }
};
</script>
