<template>
    <v-dialog v-model="dialog" max-width="950px" @click:outside="cerrarDialogo(false)">
        <v-card>
            <!--
            <v-toolbar height="50" color="#6B6A6A" dark>
                <span v-if="Item.id == null" class="text-h6">Inspección de neumático</span>
                <span v-else class="text-h6">Editar inspección</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{ Item.id }}</span>
            </v-toolbar>
            -->
            <v-card-title>
                <span v-if="Item.id == null" class="text-h6">Inspección de neumático </span>
                <span v-else class="text-h6">Editar inspección</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{ Item.id }}</span>
            </v-card-title>
            <v-card-subtitle></v-card-subtitle>
            <v-card-text>
                <v-form enctype="multipart/form-data" ref="form" v-model="valid">
                    <v-row>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="text" label="Posicion *" v-model="Item.d_posicion" hide-details="auto"
                                readonly></v-text-field>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-text-field type="text" label="Nro. Serie del Neumático: *" v-model="Item.num_serie"
                                hide-details="auto" readonly></v-text-field>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
                            <v-btn-toggle mandatory class="mt-5">
                                <v-autocomplete :items="Ejes" v-model="Item.id_ejes" label="Seleccionar Eje *" dense
                                    :readonly="habilitareje" :rules="[(v) => !!v || 'Eje es requerido']"
                                    @change="onEjes"></v-autocomplete>
                                <v-btn color="success" height="30" width="15" class="text--color:#fff" x-small
                                    @click="habilitareje = false" v-if="habilitareje == true">
                                    <v-icon x-small>mdi-lock-open-outline</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                        <div class="col-lg-4 col-sm-6 col-md-4 col-xs-12">
                            <v-autocomplete class="mt-5" :items="tiposPresion" v-model="Item.tipo_presion"
                                label="Tipo de presión *" dense
                                :rules="[(v) => !!v || 'Eje es requerido']"></v-autocomplete>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-md-4 col-xs-12" v-if="Item.accesibilidad != 0">
                            <v-text-field type="number" min="0" label="Presión actual *" autofocus v-model="Item.presion"
                                hide-details="auto" :rules="rulesPresionActual" hint="(rec. <= 160 PSI)"
                                persistent-hint></v-text-field>
                        </div>
                        <div class="col-lg-4 col-sm-12 col-md-4 col-xs-12" v-else>
                            <v-text-field type="number" label="Presión actual *" v-model="Item.presion" hide-details="auto"
                                hint="(rec. <= 160 PSI)" persistent-hint readonly></v-text-field>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Tapa de pitón</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row class="mt-2">
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-autocomplete :items="TiposValvula" v-model="Item.valvula" label="Válvula *"
                                                dense :rules="[(v) => !!v || 'Tapa es requerida']"></v-autocomplete>
                                        </div>
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-autocomplete :items="accesibilidad" v-model="Item.accesibilidad"
                                                label="Accesibilidad *" dense
                                                :rules="[(v) => !!v || 'Accesibilidad es requerida']"
                                                @change="onAccesibilidad"></v-autocomplete>
                                        </div>
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-autocomplete :items="MotivosInaccesibilidad"
                                                v-model="Item.motivo_inaccesibilidad" label="Motivo *" dense
                                                :readonly="Item.accesibilidad != '0'"></v-autocomplete>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Profundidad de Rodado (mm)</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-text-field class="pa-0" type="number" label="Izquierdo *"  @blur="onValidarRemanente('exterior')" v-model="Item.exterior" hide-details="auto" hint="milímetro" persistent-hint :rules="[(v) => !!v || 'Profundidad izquierda es requerida', (v) => v <= 100 || 'Profundidad izquierda debe ser menor a 100', (v) => {
                                                const pattern = /^\d{1,3}(\.\d{2})*(,\d+)?$/
                                                return pattern.test(v) || 'Ingrese un número válido'
                                            },
                                            (v) => this.mensajeprofundidadexterior=='' || '', ]"  min="0"></v-text-field>
                                            <span class="red--text letra-11">{{ mensajeprofundidadexterior }}</span>
                                        </div>
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-text-field class="pa-0" type="number" label="Medio *" v-model="Item.medio"
                                                hide-details="auto" hint="milímetro" persistent-hint
                                                @blur="onValidarRemanente('')" min="0">
                                            </v-text-field>
                                        </div>
                                        <div class="col-lg-12 col-sm-6 col-md-12 col-xs-12">
                                            <v-text-field class="pa-0" type="number" label="Derecho *"
                                                v-model="Item.interior" hide-details="auto" hint="milímetro" persistent-hint
                                                :rules="[
                                            v => {
                                                const pattern = /^\d{1,3}(\.\d{2})*(,\d+)?$/
                                                return pattern.test(v) || 'Ingrese un número válido'
                                            },
                                            (v) => !!v || 'Profundidad derecha es requerida',
                                            (v) => v <= 100 || 'Profundidad derecha debe ser menor a 100',
                                            (v) => this.mensajeprofundidadinterior=='' || '',
                                        ]" @blur="onValidarRemanente('interior')"
                                                min="0"></v-text-field>
                                            <span class="red--text letra-11">{{ mensajeprofundidadinterior }}</span>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Duales mal hermanados</span>
                                </v-toolbar>
                                <v-card-text>
                                    <span class="red--text letra-11">{{ mensajeejedireccional }}</span>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6" class="pa-0 pl-2">
                                            <v-checkbox v-model="Duales[0].opcion" label="Diseño" color="red" hide-details
                                                @change="onDualesMalHermanados1" :readonly="inhabilitarradio"></v-checkbox>
                                            <v-checkbox v-model="Duales[1].opcion" label="Tamaño" color="red" hide-details
                                                @change="onDualesMalHermanados1" :readonly="inhabilitarradio"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pa-0 pl-2">
                                            <v-checkbox v-model="Duales[2].opcion" label="Tipo de construcción" color="red"
                                                hide-details @change="onDualesMalHermanados1"
                                                :readonly="inhabilitarradio"></v-checkbox>
                                            <v-checkbox v-model="Duales[3].opcion" label="Medida de neumático" color="red"
                                                hide-details @change="onDualesMalHermanados1"
                                                :readonly="inhabilitarradio"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pa-0 pl-2">
                                            <v-checkbox class="mb-5" v-model="Duales[4].opcion" label="No aplica"
                                                color="red" hide-details @change="onDualesMalHermanados2"
                                                :readonly="inhabilitarradio"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Separación entre Duales</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <span class="red--text letra-11">{{ mensajeejedireccional }}</span>
                                            <v-radio-group v-model="Item.sep_duales" column>
                                                <v-radio label="Ok" color="red" value="Sep. Entre Duales- Ok"
                                                    :readonly="inhabilitarseparacion"></v-radio>
                                                <v-radio label="Muy Juntos" color="red"
                                                    value="Sep. Entre Duales- Muy Juntos"
                                                    :readonly="inhabilitarseparacion"></v-radio>
                                                <v-radio label="Muy Separados" color="red"
                                                    value="Sep. Entre Duales- Muy Separados"
                                                    :readonly="inhabilitarseparacion"></v-radio>
                                                <v-radio label="No Aplica" color="red" value="Sep. Entre Duales- No Aplica"
                                                    :readonly="inhabilitarseparacion"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Observaciones</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12" class="pa-0 pl-2">
                                            <v-checkbox v-model="Observaciones[0].opcion" label="Desg. Irregular"
                                                color="red" hide-details @change="onObservaciones"></v-checkbox>
                                            <v-autocomplete class="mt-3 letra-12" :items="listDesgasteIrregular"
                                                v-model="Item.array_desgirregular" placeholder="Seleccione" multiple dense
                                                v-if="Observaciones[0].opcion == true" @change="validacionObservaciones">
                                                <!--
                                                <template #selection="{ item, index }">
                                                    <v-chip v-if="index < 2">
                                                        {{ item.text }}
                                                    </v-chip>
                                                    <span v-else-if="index === 2 && Item.array_desgirregular.length > 3">
                                                        +{{ Item.array_desgirregular.length - 2 }} more
                                                    </span>
                                                </template>
                                                -->
                                            </v-autocomplete>
                                            <span class="red--text letra-11">{{ mensajevalidaciondesgirregular }}</span>

                                            <v-checkbox v-model="Observaciones[1].opcion" label="Para Reparar" color="red"
                                                hide-details @change="onObservaciones"></v-checkbox>
                                            <v-autocomplete class="mt-3 letra-12" :items="listParaReparar"
                                                v-model="Item.parareparar" placeholder="Seleccione" dense
                                                v-if="Observaciones[1].opcion == true"
                                                @change="validacionObservaciones"></v-autocomplete>
                                            <span class="red--text letra-11">{{ mensajevalidacionparareparar }}</span>

                                            <v-checkbox class="mb-5" v-model="Observaciones[2].opcion"
                                                label="Aro Defectuoso" color="red" hide-details></v-checkbox>

                                            <v-checkbox class="mb-5" v-model="Observaciones[3].opcion"
                                                label="Fallas en el flanco" color="red" hide-details
                                                @change="onObservaciones"></v-checkbox>
                                            <v-autocomplete class="mt-3 letra-12" :items="listFallasFlanco"
                                                v-model="Item.fallasflanco" placeholder="Seleccione" dense
                                                v-if="Observaciones[3].opcion == true"
                                                @change="validacionObservaciones"></v-autocomplete>
                                            <span class="red--text letra-11">{{ mensajevalidacionfallasflanco }}</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Estado</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <span class="red--text letra-11">{{ mensajeestadoneumatico }}</span>
                                            <v-radio-group v-model="Item.estado" column>
                                                <v-radio label="Continuar en Operación" color="red"
                                                    value="Continuar en Operación"></v-radio>
                                                <v-radio label="Lista para Reencauchar" color="red"
                                                    value="Lista para Reencauchar"></v-radio>
                                                <v-radio label="Lista para Reemplazar" color="red"
                                                    value="Lista para Reemplazar"></v-radio>
                                                <v-radio label="Neumático en Mal estado" color="red"
                                                    value="Neumático en Mal estado"></v-radio>
                                            </v-radio-group>
                                        </div>
                                        <div class="col-lg-9 col-md-9 col-sm-8 col-xs-12"
                                            v-if="Item.estado == 'Lista para Reemplazar' || Item.estado == 'Neumático en Mal estado'">
                                            <h4 class="font-bold letra-12">IMAGEN DEL NEUMATICO EN MAL ESTADO:</h4>
                                            <FileAgent @archivosubido="archivosubido" style="z-index: 0" />
                                        </div>
                                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 pa-2"
                                            v-if="Item.estado == 'Lista para Reemplazar' || Item.estado == 'Neumático en Mal estado'">
                                            <img v-if="Item.neumaticoimgruta1 != null" width="100px" height="120px"
                                                :src="ruta + Item.neumaticoimgruta1" alt="Neumatico 1" />
                                            <h4 v-else>No disponible</h4>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Estado de tuercas</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                            <v-autocomplete class="mt-5" :items="listEstadosTuerca"
                                                v-model="Item.tuercaestado" label="Estado de tuerca *" dense
                                                :rules="[(v) => !!v || 'Estado de tuerca es requerido']"
                                                @change="onEstadoTuerca"></v-autocomplete>
                                        </div>
                                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                            <v-text-field type="number" min="0" label="Cantidad de tuercas"
                                                v-model="Item.tuercacantidad" hide-details="auto"
                                                :rules="rulesCantidadTuercas"
                                                :readonly="Item.tuercaestado == 1"></v-text-field>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <v-card>
                                <v-toolbar height="40" color="#6B6A6A" dark>
                                    <span class="letra-14">Recomendación</span>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <div class="col-lg-12 col-sm-12 col-md-12 col-xs-12">
                                            <v-text-field class="mb-4" type="text" label="Recomendación *"
                                                v-model="Item.recomendacion" hide-details="auto"></v-text-field>
                                        </div>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                            <p>
                                <font color="red" class="pa-2">*</font> Datos obligatorios
                            </p>
                        </div>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo()"> Cerrar </v-btn>
                <v-btn v-if="Item.id == null" color="info" :loading="loading" @click="agregar()">
                    Guardar
                </v-btn>
                <v-btn v-if="Item.id != null" color="info" :loading="loading" @click="agregar()" >
                    Editar
                </v-btn>
            </v-card-actions>
            <MensajeTransicion :color="color" :text="mensaje" v-if="verAlerta" :top="false" :bottom="true" />
        </v-card>
    </v-dialog>
</template>

<script>
import {
    mapActions,
    mapState
} from "vuex";
import {
    axios2 as axios
} from "@/interceptor/useApi";
import FileAgent from "../FileAgent.vue";
import global from "../../global";
import MensajeTransicion from "../Alerta.vue"

export default {
    props: ["dialog", "Item", "Cliente", "Ejes", "MotivosInaccesibilidad", "TiposValvula", "Duales", "Observaciones"],
    components: {
        FileAgent,
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            habilitareje: true,
            tiposPresion: [{
                value: "PSI",
                text: "PSI"
            }],
            accesibilidad: [{
                value: '1',
                text: "SI"
            },
            {
                value: '0',
                text: "NO"
            }
            ],
            rulesPresionActual: [(v) => !!v || 'Presión actual es requerido', (v) => v <= 160 || 'Presión actual debe ser menor o igual a 160'],
            rulesProfundidadExterior: [
                (v) => !!v || 'Profundidad izquierda es requerida',
                (v) => v <= 100 || 'Profundidad izquierda debe ser menor a 100',
                (v) => {
                    const pattern = /^\d{1,3}(\.\d{2})*(,\d+)?$/
                    return pattern.test(v) || 'Ingrese un número válido'
                }
            ],
            rulesProfundidadMedia: [
                v => {
                    const pattern = /^\d{1,3}(\.\d{2})*(,\d+)?$/
                    return pattern.test(v) || 'Ingrese un número válido'
                },
                (v) => !!v || 'Profundidad media es requerida',
                (v) => v <= 100 || 'Profundidad media debe ser menor a 100',
            ], 
            rulesCantidadTuercas: [],
            mensajeprofundidadexterior: "",
            mensajeprofundidadinterior: "",
            mensajevalidaciondesgirregular: "",
            mensajevalidacionparareparar: "",
            mensajevalidacionfallasflanco: "",
            mensajeestadoneumatico: "",
            mensajeejedireccional: "",
            validaciondesgirregular: true,
            validacionparareparar: true,
            validacionfallasflanco: true,
            inhabilitarradio: true,
            inhabilitarseparacion: false,
            listDesgasteIrregular: [{
                value: '1',
                text: "DESGASTE ONDULADO"
            },
            {
                value: '2',
                text: "DESGASTE TIPO DIENTES DE SIERRA"
            },
            {
                value: '3',
                text: "DESGASTE EN LOS HOMBROS"
            },
            {
                value: '4',
                text: "DESGASTE TIPO RIVERA"
            },
            {
                value: '5',
                text: "DESGASTE UNILATERAL"
            },
            {
                value: '6',
                text: "DESGASTE DIAGONAL"
            },
            {
                value: '7',
                text: "DESGASTE EXCENTRICO"
            },
            {
                value: '8',
                text: "DESGASTE DE UN SOLO HOMBRO"
            },
            {
                value: '9',
                text: "DESGASTE TIPO DEPRESION INTERMITENTE"
            },
            {
                value: '10',
                text: "DESGASTE EN ESCALON"
            },
            {
                value: '11',
                text: "DESGASTE PUNTA Y TALON"
            },
            {
                value: '12',
                text: "DESGASTE ALTERNADO DE TACOS"
            },
            {
                value: '13',
                text: "DESGASTE LOCALIZADO"
            },
            {
                value: '14',
                text: "DESGASTE CENTRAL"
            },
            {
                value: '15',
                text: "DESGASTE TIPO ISLA"
            },
            {
                value: '16',
                text: "CHIPPING"
            },
            {
                value: '17',
                text: "CHUNKING"
            },
            {
                value: '18',
                text: "DESGASTE DE RIBETE(S)"
            },
            ],
            listParaReparar: [{
                value: '1',
                text: "BANDA DE RODAMIENTO"
            },
            {
                value: '2',
                text: "HOMBRO"
            },
            {
                value: '3',
                text: "FLANCO"
            },
            {
                value: '4',
                text: "PESTAÑA"
            }
            ],
            listFallasFlanco: [{
                value: '1',
                text: "RODURA RADIAL"
            },
            {
                value: '2',
                text: "ROTURA DIAGONAL"
            },
            {
                value: '3',
                text: "CORTE LATERAL"
            },
            {
                value: '4',
                text: "EXPOSICION DE CUERDAS"
            },
            {
                value: '5',
                text: "PROTUBERANCIA"
            },
            {
                value: '6',
                text: "RESEQUEDAD"
            },
            {
                value: '7',
                text: "DAÑO UNIFORME DEL CAUCHO POR ROZAMINETO"
            },
            {
                value: '8',
                text: "IMPACTO LATERAL"
            },
            ],
            listEstadosTuerca: [{
                value: 1,
                text: "OK"
            },
            {
                value: 2,
                text: "Tuerca Robada"
            },
            {
                value: 3,
                text: "Tuerca Floja"
            },
            {
                value: 4,
                text: "Falta Tuerca"
            },
            {
                value: 5,
                text: "Cambiar Espárrago"
            },
            {
                value: 6,
                text: "Huacha Floja"
            },
            ],
            ruta: global.ruta_image,
            loading: false,
            mensaje: "",
            color: "success",
            verAlerta: false
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
    },
    methods: {
        ...mapActions("inspeccion", ["validarRemanente"]),

        archivosubido: function ({
            valor,
            fileRecords
        }) {
            this.Item.ruta = fileRecords;
        },

        onEjes: function () {
            if (this.Item.id_ejes == 1) {
                this.Duales[4].opcion = true;
                this.onDualesMalHermanados2();
                this.inhabilitarradio = true;
                this.inhabilitarseparacion = true;
            } else {
                this.inhabilitarradio = false;
                this.inhabilitarseparacion = false;
            }
        },

        onAccesibilidad: function () {
            if (this.Item.accesibilidad == 1) {
                this.Item.motivo_inaccesibilidad = null;
                if (this.Item.presion == 0) {
                    this.Item.presion = null;
                }
            } else {
                this.Item.presion = 0;
            }
        },

        onObservaciones: function () {
            if (this.Observaciones[0].opcion == false) {
                this.Item.array_desgirregular = [];
            }
            if (this.Observaciones[1].opcion == false) {
                this.Item.parareparar = null;
            }
            if (this.Observaciones[3].opcion == false) {
                this.Item.fallasflanco = null;
            }
            this.validacionObservaciones();
        },

        onDualesMalHermanados1: function () {
            if (this.Duales[0].opcion == true || this.Duales[1].opcion == true || this.Duales[2].opcion == true || this.Duales[3].opcion == true) {
                this.Duales[4].opcion = false;
                this.inhabilitarradio = false;
            }
        },

        onDualesMalHermanados2: function () {
            if (this.Duales[4].opcion == true) {
                this.Duales[0].opcion = false;
                this.Duales[1].opcion = false;
                this.Duales[2].opcion = false;
                this.Duales[3].opcion = false;
                this.inhabilitarradio = true;
            }
        },

        onValidarRemanente: async function (descripcion) {
            console.log(this.Item,'posiciones');
            let minimo = Math.min(this.Item.exterior ?? 1000, this.Item.interior ?? 1000);
            console.log(this.Item,'Item');
            if (minimo <= this.Item.rem_proximo) {
                this.mensajeestadoneumatico = "Elige la opción correcta para NSD = " + minimo.toFixed(1);
            } else {
                this.mensajeestadoneumatico = "";
            }

            if (descripcion != '') {
                this.Item.descripcion_remanente_validado=descripcion;
                let mensaje = await this.validarRemanente(this.Item);
                if (mensaje != "Correcto") {
                    if (descripcion == "exterior") {
                        console.log(descripcion,'1');
                        this.mensajeprofundidadexterior = mensaje;
                    }
                    if (descripcion == "interior") {
                        console.log(descripcion,'2');
                        this.mensajeprofundidadinterior = mensaje;
                    }
                } else {
                    if (descripcion == "exterior") {
                        this.mensajeprofundidadexterior = "";
                    }
                    if (descripcion == "interior") {
                        this.mensajeprofundidadinterior = "";
                    }
                }
            }
        },

        onEstadoTuerca: function () {
            if (this.Item.tuercaestado == 1) {
                this.Item.tuercacantidad = null;
                this.rulesCantidadTuercas = [];
            } else {
                this.rulesCantidadTuercas = [(v) => !!v || 'Cantidad de tuercas es requerido']
            }
        },

        cerrarDialogo: function (valor = false) {
            this.$refs.form.reset();
            this.$emit("cerrarModal", valor);
        },

        asignarValores: function () {
            this.Item.resultado = "";
            let resultado = "";
            let desgirregular = "";
            //let parareparar = "";
            let otros = "";

            this.Duales.forEach((element) => {
                if (element.opcion == true) {
                    resultado += element.text + ',';
                }
            });

            this.Observaciones.forEach(element => {
                if (element.opcion == true) {
                    otros += element.name + ',';
                }
            });

            this.Item.array_desgirregular.forEach(element => {
                desgirregular += element + ",";
            });

            if (this.Item.estado == "Continuar en Operación" || this.Item.estado == "Lista para Reencauchar") {
                this.Item.ruta = [];
            }

            //valores
            this.Item.resultado = resultado.substring(0, resultado.length - 1);
            this.Item.desgirregular = desgirregular != "" ? desgirregular.substring(0, desgirregular.length - 1) : null;
            //this.Item.parareparar = parareparar != "" ? parareparar.substring(0, parareparar.length - 1) : null;
            //this.Item.fallasflanco = fallasflanco != "" ? fallasflanco.substring(0, fallasflanco.length - 1) : null;
            this.Item.otros = otros != "" ? otros.substring(0, otros.length - 1) : "";
        },

        validacionObservaciones: function () {
            //validacion de observaciones
            if (this.Observaciones[0].opcion == true) {
                if (this.Item.array_desgirregular.length == 0) {
                    this.mensajevalidaciondesgirregular = "Seleccione observaciones de desgaste irregular";
                    this.validaciondesgirregular = false;
                } else {
                    this.mensajevalidaciondesgirregular = "";
                    this.validaciondesgirregular = true;
                }
            } else {
                this.mensajevalidaciondesgirregular = "";
                this.validaciondesgirregular = true;
            }

            if (this.Observaciones[1].opcion == true) {
                if (this.Item.parareparar == null) {
                    this.mensajevalidacionparareparar = "Seleccione observación para reparar";
                    this.validacionparareparar = false;
                } else {
                    this.mensajevalidacionparareparar = "";
                    this.validacionparareparar = true;
                }
            } else {
                this.mensajevalidacionparareparar = "";
                this.validacionparareparar = true;
            }

            if (this.Observaciones[3].opcion == true) {
                if (this.Item.fallasflanco == null) {
                    this.mensajevalidacionfallasflanco = "Seleccione falla en el flanco";
                    this.validacionfallasflanco = false;
                } else {
                    this.mensajevalidacionfallasflanco = "";
                    this.validacionfallasflanco = true;
                }
            } else {
                this.mensajevalidacionfallasflanco = "";
                this.validacionfallasflanco = true;
            }

        },

        agregar: async function () {
            //recorrer array Duales y guardar en variable resultado una cadena separada por comas
            //completar texto a opcion sep_duales
            this.asignarValores();
            let autorizacion = true;
            if (this.$refs.form.validate()) {
                if (this.validaciondesgirregular == true && this.validacionparareparar == true && this.validacionfallasflanco == true) {
                    try {
                        let url = "";
                        this.loading = true;
                        if (this.Item.id == null) {
                            url = "inspecciones/storeInspeccion";
                        } else {
                            url = "inspecciones/updateInspeccion"; 
                        }

                        const data = {
                            id_cliente: localStorage.getItem("idcliente"),
                            id_usuario: localStorage.getItem("id"),
                            id: this.Item.id,
                            id_vehiculo: this.Item.id_vehiculo,
                            km_inspeccion_parcial: this.Item.km_inspeccion,
                            posicion: this.Item.posicion,
                            id_neumaticos: this.Item.id_neumaticos,
                            serieneumatico: this.Item.num_serie,
                            id_ejes: this.Item.id_ejes,
                            tipo_presion: this.Item.tipo_presion,
                            presion: this.Item.presion,
                            valvula: this.Item.valvula,
                            accesibilidad: this.Item.accesibilidad,
                            motivo_inaccesibilidad: this.Item.motivo_inaccesibilidad,
                            exterior: this.Item.exterior,
                            medio: this.Item.medio,
                            interior: this.Item.interior,
                            resultado: this.Item.resultado,
                            separaciond: this.Item.sep_duales,
                            otros: this.Item.otros,
                            desgirregular: this.Item.desgirregular,
                            parareparar: this.Item.parareparar,
                            fallasflanco: this.Item.fallasflanco,
                            tuercaestado: this.Item.tuercaestado,
                            tuercacantidad: this.Item.tuercacantidad,
                            estado: this.Item.estado,
                            recomendacion: this.Item.recomendacion,
                            ruta: this.Item.ruta==null?this.Item.neumaticoimgruta1:this.Item.ruta,
                            proyecto: "web"
                        }; 
                            await axios
                                .post(url, data)
                                .then(async (response) => {
                                    this.loading = false;
                                    await this.$emit("agregarInspeccionParcial", response.data.inspeccion_actual);
                                    this.cerrarDialogo(true);
                                })
                                .catch((error) => {
                                    this.errorMessage = error.response.data;
                                    console.error("error!", error);
                                }); 
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    console.log("aaa");
                }
            }
        },
    },
    mounted() {
        if (this.Item.id_ejes == 1) {
            this.mensajeejedireccional = "No aplicable para el eje direccional";
            this.inhabilitarradio = true;
            this.inhabilitarseparacion = true;
        } else {
            this.inhabilitarradio = false;
            this.inhabilitarseparacion = false;
            this.mensajeejedireccional = "";
        }
    }
};
</script>

<style scoped>.letra-14 {
    font-size: 16px;
}

.letra-11 {
    font-size: 11px;
}

.letra-12 {
    font-size: 12px;
}</style>
