<template>
  <v-layout justify-center align-center fill-height
    style="background-image: url(/images/background/fondo.jpg); width: 100% !important;min-height: 100vh; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative; z-index: 1; margin: 0 !important;">

    <v-container fluid fill-height text-center>
      <v-layout align-center justify-center pa-10>
        <v-flex xl3 lg4 md6 sm6 xs12>
          <v-card class="elevation-12 rounded-xl pa-4">
            <img style="width: 170px;" src="/images/background/logo2.png">
            <br>
            {{
              isRegister ? stateObj.register.name : stateObj.login.name
            }}
            <v-card-text>
              <v-form ref="form" lazy-validation @submit.prevent="isRegister ? register() : validate()">
                <v-text-field v-if="isRegister" v-model="Empresa" label="Nombre de la Empresa" hide-details="auto"
                  prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-if="isRegister" v-model="Nombre" label="Nombre del Cliente" hide-details="auto"
                  prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-if="isRegister" v-model="Telefono" label="Telefono" hide-details="auto"
                  prepend-icon="mdi-phone"></v-text-field>
                <v-text-field v-if="isRegister" prepend-icon=" mdi-email" v-model="email" :rules="emailRules" name="email"
                  label="Email" type="text" placeholder="Email" required hide-details="auto"></v-text-field>
                <v-text-field v-else prepend-icon="mdi-account" v-model="email" :rules="emailRules" name="email"
                  label="Email" type="text" placeholder="Email" required hide-details="auto"></v-text-field>

                <v-text-field v-model="password" prepend-icon="mdi-lock" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1"
                  label="Contraseña" hint="Al menos 6 caracteres" counter @click:append="show1 = !show1"></v-text-field>

                <v-text-field v-if="isRegister" prepend-icon="mdi-lock" v-model="confirmPassword" name="confirmPassword"
                  label="Confirm Password" type="password" placeholder="Confirmar Contraseña" required></v-text-field>
                <v-checkbox v-if="isRegister" v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      He leído y acepto los
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank" href="https://vuetifyjs.com" @click.stop v-on="on">
                            Terminos de uso
                          </a>
                        </template>
                        Ver Terminos de Uso
                      </v-tooltip>
                      ***
                    </div>
                  </template>
                </v-checkbox>
                <div class="red--text text--lighten-1 text-body-2 mb-4">
                  {{ errorMessage }}
                </div>
                <v-btn :loading="loading" :disabled="!valid" dark type="submit" class="mb-5" color="error" value="log in"
                  submit>{{
                    isRegister
                    ? stateObj.register.namebtn
                    : stateObj.login.namebtn
                  }}</v-btn>

                <!-- <div class="grey--text mt-4" color="blue accent-4" v-on:click="isRegister = !isRegister;">
                        {{toggleMessage}}  
                      </div> -->
                <div class="pa-2">
                  <a color="blue accent-4" @click="insertarRuta('/login')">
                    ¿Ya tienes una cuenta?
                  </a>
                  ¿Aun no tienes una cuenta?
                  <a color="blue accent-4" @click="CambioPantalla()">
                    Registrate
                  </a>
                </div> 
              </v-form>
            </v-card-text>
            <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>
<script>
import { mapActions } from 'vuex';
import MensajeTransicion from '@/components/Alerta.vue'
import Swal from 'sweetalert2';

export default {
  name: "App",
  components: {
    MensajeTransicion
  },
  data() {
    return {
      loading: false,
      valid: true,
      checkbox: true,
      username: "",
      confirmPassword: "",
      isRegister: false,
      errorMessage: "",
      stateObj: {
        register: {
          name: "Registrar Usuario",
          namebtn: "Registrar",
          message: "¿Ya tienes una cuenta?",
        },
        login: {
          name: "",
          namebtn: "Ingresar",
          message: "¿Ahún no tienes una cuenta ? Registrate",
        },
      },
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
      ],
      show1: false,
      password: '',
      rules: {
        required: v => !!v || 'Requerido.',
        min: v => v.length >= 6 || 'Min 6 characters'
      },
      verAlerta: false,
      mensaje: "",
    }
  },
  created() {
  },
  methods: {
    insertarRuta(param){
      router.push(param);
    },
    register() {
      if (this.password == this.confirmPassword) {
        if (this.$refs.form.validate()) {
          const data = { Empresa: this.Empresa, name: this.Nombre, Telefono: this.Telefono, email: this.email, password: this.password };
          this.registrocliente(data)
        }
      } else {
        this.errorMessage = "Las contraseñas no coinciden";
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const data = { email: this.email, password: this.password };
        const response = await this.login(data);

        if (!response.status) {
          this.loading = false;
          this.mensaje = response.message;
          this.verAlerta = true;
          setTimeout(() => [(this.verAlerta = false)], 4000);
        }
      }
    },
    CambioPantalla() {
      this.isRegister = !this.isRegister
      this.$refs.form.resetValidation()
    },
    ...mapActions('login', ['mockLogin', 'registrocliente', 'login'])
  },
  computed: {
    toggleMessage: function () {
      return this.isRegister
        ? this.stateObj.register.message
        : this.stateObj.login.message;
    },
  },
};
</script>
