import { render, staticRenderFns } from "./nuevo.vue?vue&type=template&id=3d8b3cf3&"
import script from "./nuevo.vue?vue&type=script&lang=js&"
export * from "./nuevo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports