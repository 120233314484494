import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import About from '../views/AboutView.vue'
import Usuarios from '../views/Usuarios.vue'
import Imagenes from '../views/Imagenes.vue'
import Roles from '../views/Roles.vue'
import Login from '../views/Login.vue'
import FormularioDinamico from '../views/FormularioDinamico.vue'
import Excel from '../views/Excel.vue'
import Permisos from '../views/Permisos.vue'
import Modulos from '../views/Modulos.vue'
import MttoCliente from '../views/MttoCliente.vue'
import GestionCliente from '../views/UsuarioPorCliente.vue'
import SeleccionarCliente from '../views/SeleccionarCliente.vue'
import CriterioAceptacion from '../views/CriterioAceptacion.vue'
import TipoCambio from '../views/TipoCambio.vue'
import Aplicacion from '../views/Aplicacion.vue'
import ImportarVehiculos from '../views/ImportarVehiculos.vue'
import ImportarNeumatico from '../views/ImportarNeumatico.vue'
import ImportarCriterio from '../views/ImportarCriterio.vue'
import Plantas from '../views/Plantas.vue'
import AdministradorReportes from '../views/reportes/AdministradorReportes.vue'
import Vehiculos from '../views/Vehiculos.vue'
import InstalacionNeumaticos from '../views/InstalacionNeumaticos.vue'
import Neumaticos from '../views/Neumaticos.vue'
import NeumaticosEnScrap from '../views/NeumaticosEnScrap.vue'
import NeumaticosEnReencauche from '../views/NeumaticosEnReencauche.vue'
import NeumaticosEnReparacion from '../views/NeumaticosEnReparacion.vue'
import NeumaticosEnMalEstado from '../views/NeumaticosEnMalEstado.vue'
import NeumaticoReporteCosto from '../views/NeumaticoReporteCosto.vue'
import NeumaticoReporteScrap from '../views/NeumaticoReporteScrap.vue'
import NeumaticoReporteKilometro from '../views/NeumaticoReporteKilometro.vue'
import ReporteConsolidado from '../views/reportes/ReporteConsolidado.vue'
import ReporteCurvaDesgasteNeumatico from '../views/reportes/ReporteCurvaDesgasteNeumatico.vue'
import ReporteCurvaDesgasteVehiculo from '../views/reportes/ReporteCurvaDesgasteVehiculo.vue'
import ReporteRemanenteRodadoVehiculo from '../views/reportes/ReporteRemanenteRodadoVehiculo.vue'
import ReporteScrapMotivo from '../views/reportes/ReporteScrapMotivo.vue'
import ReporteScrapMarca from '../views/reportes/ReporteScrapMarca.vue'
import ReportePerdidasScrap from '../views/reportes/ReportePerdidasScrap.vue'
import ReporteComparativoConfiguracion from '../views/reportes/ReporteComparativoConfiguracion.vue'
import ReporteComparativoMarca from '../views/reportes/ReporteComparativoMarca.vue'
import Inspeccion from '../views/Inspeccion.vue'
import InspeccionReporte from '../views/InspeccionReporte.vue'
import IndicadorKmHr from '../views/IndicadorKmHr.vue'
import IndicadorReencauche from '../views/IndicadorReencauche.vue'
import IndicadorReencauchabilidad from '../views/IndicadorReencauchabilidad.vue'
import ReporteDetalladoPorCliente from '../views/ReportesDetalladoPorCliente.vue'
import MiembrosCliente from '../views/Miembros.vue'
import ChangePassword from '../views/ChangePassword.vue'
import MiCuenta from '../views/MiCuenta.vue'
import ReporteSesionUsuarios from '../views/reportes_usuarios/SesionUsuarios.vue'
import ReporteAccionUsuarios from '../views/reportes_usuarios/AccionUsuarios.vue'
import ReporteInformeTecnicos from '../views/reportes_usuarios/InformeTecnicos.vue'
import GestionClientesConSuscripcion from '../views/ClientesConSuscripcion.vue'
import ReportesMarketing from '../views/ReportesMarketing.vue'

import ReporteMarketingReencauchados from '../views/marketing/neumaticosreencauchados.vue'
import ReporteMarketingMedidas  from '../views/marketing/medidaneumaticos.vue'
import ReporteMarketingDiseniosxEmpresa from '../views/marketing/disenioneumaticosxempresa.vue'
import ReporteMarketingMedidasxSector  from '../views/marketing/medidaneumaticosxsector.vue'
import ReporteMarketingRendimientoNeumaticos  from '../views/marketing/rendimientoneumaticos.vue'
import page404  from '../views/page404.vue'
import resetpassword  from '../views/ResetPassword.vue'
import store from '@/store'

Vue.use(VueRouter)

var existe = false; 
const routes = [
  {
    path: '/',
    name: 'welcome',
    component: About,
    meta: { id:0  },
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,    
    meta: { requiresAuth: true ,id:19 },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { id:0  },
  },
  {
    path: '/page404',
    name: 'page404',
    component: page404,
    meta: { id:0  },
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: resetpassword,
    meta: { id:0  },
  },
  {
    path: '/changeyourpassword',
    name: 'changeyourpassword',
    component: ChangePassword,
    meta: { id:0  },
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios,
    meta: { requiresAuth: true, sesioncliente: true,id:5  },
  },
  /* {
    path: '/usuarios/:id',
    name: 'usuarios',
    component: Usuarios,
    meta: { requiresAuth: true, sesioncliente: true  },
  },
  {
    path: '/imagenes',
    name: 'imagenes',
    component: Imagenes,
    meta: { requiresAuth: true, sesioncliente: true  },
  }, */
  {
    path: '/roles',
    name: 'roles',
    component: Roles,
    meta: { requiresAuth: true, sesioncliente: true ,id:3 },
  },
  {
    path: '/permisos',
    name: 'permisos',
    component: Permisos,
    meta: { requiresAuth: true, sesioncliente: true ,id:4 },
  },
  {
    path: '/modulos',
    name: 'modulos',
    component: Modulos,
    meta: { requiresAuth: true, sesioncliente: true ,id:30 },
  },
  {
    path: '/mttoCliente',
    name: 'mttoCliente',
    component: MttoCliente,
    meta: { requiresAuth: true, sesioncliente: true ,id:6 },
  }, 
  {
    path: '/gestionCliente',
    name: 'gestionCliente',
    component: GestionCliente,
    meta: { requiresAuth: true, sesioncliente: true,id:7  },
  }, 
  {
    path: '/gestionClientesConSuscripcion',
    name: 'gestionClientesConSuscripcion',
    component: GestionClientesConSuscripcion,
    meta: { requiresAuth: true, sesioncliente: true,id:0   },
  }, 
  {
    path: '/miembroscliente/:id',
    name: 'miembroscliente',
    component: MiembrosCliente,
    meta: { requiresAuth: true, sesioncliente: true,id:7    },
  },
  {
    path: '/seleccionarCliente',
    name: 'seleccionarCliente',
    component: SeleccionarCliente,
    meta: { requiresAuth: true, sesioncliente: true, id:1},
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: ReportesMarketing,
    meta: { requiresAuth: true, sesioncliente: true,id:0},
  },
  {
    path: '/reportemarketingreencauchados',
    name: 'reportemarketingreencauchados',
    component: ReporteMarketingReencauchados,
    meta: { requiresAuth: true, sesioncliente: true,id:15},
  },
  {
    path: '/reportemarketingmedidas',
    name: 'reportemarketingmedidas',
    component: ReporteMarketingMedidas,
    meta: { requiresAuth: true, sesioncliente: true,id:16},
  },
  {
    path: '/reportemarketingdiseniosxempresa',
    name: 'reportemarketingdiseniosxempresa',
    component: ReporteMarketingDiseniosxEmpresa,
    meta: { requiresAuth: true, sesioncliente: true,id:17},
  },
  {
    path: '/reportemarketingmedidasxsector',
    name: 'reportemarketingmedidasxsector',
    component: ReporteMarketingMedidasxSector,
    meta: { requiresAuth: true, sesioncliente: true,id:18},
  },
  {
    path: '/reportemarketingrendimientoneumaticos',
    name: 'reportemarketingrendimientoneumaticos',
    component: ReporteMarketingRendimientoNeumaticos,
    meta: { requiresAuth: true, sesioncliente: true,id:0},
  },
  {
    path: '/salirCliente',
    name: 'leave',
    component: SeleccionarCliente,
    meta: { requiresAuth: true, sesioncliente: true,id:0},
  },
  {
    path: '/formulariodinamico',
    name: 'formulariodinamico',
    component: FormularioDinamico,
    meta: { requiresAuth: true,sesioncliente: true ,id:0 },
  },
  {
    path: '/excel',
    name: 'excel',
    component: Excel,
    meta: { requiresAuth: true, sesioncliente: true ,id:0 },
  },
  {
    path: '/reportesesionusuarios',
    name: 'reportesesionusuarios',
    component: ReporteSesionUsuarios,
    meta: { requiresAuth: true, sesioncliente: true,id:13 }
  },
  {
    path: '/reporteaccionusuarios',
    name: 'reporteaccionusuarios',
    component: ReporteAccionUsuarios,
    meta: { requiresAuth: true, sesioncliente: true,id:14  }
  },
  {
    path: '/reporteinformetecnicos',
    name: 'reporteinformetecnicos',
    component: ReporteInformeTecnicos,
    meta: { requiresAuth: true, sesioncliente: true,id:15  }
  },
  //Sección de configuracion
  {
    path: '/criterioaceptacion',
    name: 'criterioaceptacion',
    component: CriterioAceptacion,
    meta: { requiresAuth: true, id:21},
  },
  {
    path: '/tipocambio',
    name: 'tipocambio',
    component: TipoCambio,
    meta: { requiresAuth: true,id:22 },
  },
  {
    path: '/aplicacion',
    name: 'aplicacion',
    component: Aplicacion,
    meta: { requiresAuth: true,id:23 },
  },
  {
    path: '/importarvehiculos',
    name: 'importarvehiculos',
    component: ImportarVehiculos,
    meta: { requiresAuth: true,id:26 },
  },
  {
    path: '/importarneumatico',
    name: 'importarneumatico',
    component: ImportarNeumatico,
    meta: { requiresAuth: true,id:27 },
  },
  {
    path: '/importarcriterio',
    name: 'importarcriterio',
    component: ImportarCriterio,
    meta: { requiresAuth: true,id:28 },
  },
  {
    path: '/plantas',
    name: 'plantas',
    component: Plantas,
    meta: { requiresAuth: true,id:29 },
  },
  {
    path: '/administracionreportes',
    name: 'administracionreportes',
    component: AdministradorReportes,
    meta: { requiresAuth: true, id:24 },
  },
  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: Vehiculos,
    meta: { requiresAuth: true,id:73 },
  },
  {
    path: '/vehiculo/instalacion/:id',
    name: 'vehiculoinstalacion',
    component: InstalacionNeumaticos,
    meta: { requiresAuth: true , id:0 },
  },
  {
    path: '/neumaticos',
    name: 'neumaticos',
    component: Neumaticos,
    meta: { requiresAuth: true,id:75 }
  },
  {
    path: '/neumaticoreportecosto/:id',
    name: 'neumaticoreportecosto',
    component: NeumaticoReporteCosto,
    meta: { requiresAuth: true ,id:0 }
  },
  {
    path: '/neumaticoreportescrap/:id',
    name: 'neumaticoreportescrap',
    component: NeumaticoReporteScrap,
    meta: { requiresAuth: true,id:75 }
  },
  {
    path: '/neumaticoreportekilometro/:id',
    name: 'neumaticoreportekilometro',
    component: NeumaticoReporteKilometro,
    meta: { requiresAuth: true,id:75 }
  },
  {
    path: '/configuracionvehiculo',
    name: 'configuracionvehiculo',
    component: ()=> import('../views/ConfiguracionVehiculo.vue'),
    meta: { requiresAuth: true,id:74 }
  },
  {
    path: '/configuracionvehiculo/instalacion/:id',
    name: 'configuracionvehiculoinstalacionid',
    component: ()=> import('../views/ConfiguracionVehiculoInstalacion.vue'),
    meta: { requiresAuth: true ,id:0}
  },
  {
    path: '/configuracionvehiculo/instalacion',
    name: 'configuracionvehiculoinstalacion',
    component: ()=> import('../views/ConfiguracionVehiculoInstalacion.vue'),
    meta: { requiresAuth: true ,id:0}
  },
  //SECCIÓN REPORTES
  {
    path: '/reporteconsolidado',
    name: 'reporteconsolidado',
    component: ReporteConsolidado,
    meta: { requiresAuth: true,id:80 }
  },
  {
    path: '/reportecurvadesgasteneumatico',
    name: 'reportecurvadesgasteneumatico',
    component: ReporteCurvaDesgasteNeumatico,
    meta: { requiresAuth: true,id:81 }
  },
  {
    path: '/reportecurvadesgastevehiculo',
    name: 'reportecurvadesgastevehiculo',
    component: ReporteCurvaDesgasteVehiculo,
    meta: { requiresAuth: true,id:82 }
  },
  {
    path: '/reporteremanenterodadovehiculo',
    name: 'reporteremanenterodadovehiculo',
    component: ReporteRemanenteRodadoVehiculo,
    meta: { requiresAuth: true,id:83 }
  },
  {
    path: '/reportescrapmotivo',
    name: 'reportescrapmotivo',
    component: ReporteScrapMotivo,
    meta: { requiresAuth: true,id:84 }
  },
  {
    path: '/reportescrapmarca',
    name: 'reportescrapmarca',
    component: ReporteScrapMarca,
    meta: { requiresAuth: true,id:85 }
  },
  {
    path: '/reporteperdidasscrap',
    name: 'reporteperdidasscrap',
    component: ReportePerdidasScrap,
    meta: { requiresAuth: true,id:86 }
  },
  {
    path: '/reportecomparativoconfiguracion',
    name: 'reportecomparativoconfiguracion',
    component: ReporteComparativoConfiguracion,
    meta: { requiresAuth: true,id:87 }
  },
  {
    path: '/reportecomparativomarca',
    name: 'reportecomparativomarca',
    component: ReporteComparativoMarca,
    meta: { requiresAuth: true,id:88 }
  },
  //Sección de mantenedores
  {
    path: '/neumaticosscrap',
    name: 'neumaticosscrap',
    component: NeumaticosEnScrap,
    meta: { requiresAuth: true,id:76 }
  },
  {
    path: '/neumaticosreencauche',
    name: 'neumaticosreencauche',
    component: NeumaticosEnReencauche,
    meta: { requiresAuth: true,id:77 }
  },
  {
    path: '/neumaticosreparacion',
    name: 'neumaticosreparacion',
    component: NeumaticosEnReparacion,
    meta: { requiresAuth: true,id:90 }
  },
  {
    path: '/neumaticosmalestado',
    name: 'neumaticosmalreparacion',
    component: NeumaticosEnMalEstado,
    meta: { requiresAuth: true,id:78 }
  },
  //Inspeccion
  {
    path: '/inspeccion',
    name: 'inspeccion',
    component: Inspeccion,
    meta: { requiresAuth: true,id:70 }
  },
  {
    path: '/inspeccionreporte',
    name: 'inspeccionreporte',
    component: InspeccionReporte,
    meta: { requiresAuth: true,id:71 }
  },
  {
    path: '/indicadorkmhr',
    name: 'indicadorkmhr',
    component: IndicadorKmHr,
    meta: { requiresAuth: true,id:65 }
  },
  {
    path: '/indicadorreencauche',
    name: 'indicadorreencauche',
    component: IndicadorReencauche,
    meta: { requiresAuth: true,id:66 }
  },
  {
    path: '/indicadorreencauchabilidad',
    name: 'indicadorreencauchabilidad',
    component: IndicadorReencauchabilidad,
    meta: { requiresAuth: true,id:67 }
  },
  {
    path: '/reportedetalladoporcliente',
    name: 'reportedetalladoporcliente',
    component: ReporteDetalladoPorCliente,
    meta: { requiresAuth: true,id:0 }
  },
  {
    path: '/micuenta',
    name: 'micuenta',
    component: MiCuenta,
    meta: { requiresAuth: true,id:0 }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  //verificamos si la ruta que esta llegando existe en las rutas que tenemos 
  routes.forEach(element => {
    if(element.name == to.name){
      existe = true;
    }
  })
  console.log(existe,'existe');
  //salirdelcliente, borrar el key('cliente') == null
  if(existe){
    //Si existe Verificamos si esta logueado, de ser el caso cuando entren a login redireccionar a la ruta seleccionaCliente
    if(localStorage.getItem('logueo') && (to.name=="login" || to.name=="welcome") ){
      next('/seleccionarCliente')
    }
    //Recorremos todas las rutas que tienen en el meta requiresAuth
    if(to.matched.some(route =>route.meta.requiresAuth)){
      //Verificamos que este logueado si no esta logueado redirecciona a login en caso contrario sigue normal 
      if(!localStorage.getItem('logueo')){        
        next('/login')
      }else{
        //si aun no ah seleccionado cliente 
        if(localStorage.getItem('idcliente') != null){  
          //si la ruta que esta entrando es parte del primer panel, paso directo al siguiente menu, 
          //de lo contrario sigo la ruta normal ya que es del segundo panel
          if(to.matched.some(route =>route.meta.sesioncliente)){    
            next('/home')
            console.log("222");
          }else{
            console.log("333");
            

            // Asegúrate de que 'store' esté importado en este archivo
            const permisos = store.state.navigation.Permisos;

            // Verificamos si los permisos están vacíos
            if (Object.keys(permisos).length === 0) {
              // Ejecutar la acción loadRoutes
              store.dispatch('navigation/loadRoutes')
              .then(() => {
                console.log(store.state.navigation.Permisos);
                if (store.state.navigation.Permisos[to.meta.id]==undefined && to.meta.id!=0) {
                  next('/page404')  
                  //  next('/page404')  

                }else{
                  next();
                }
    
              });

              // Puedes agregar una lógica adicional aquí, si es necesario
            }
            next()
          }
        }else{
          //verificamos si la ruta entrante es parte de las primera rutas 
          if(to.matched.some(route =>route.meta.sesioncliente)){  
            console.log("444"); 

            // Asegúrate de que 'store' esté importado en este archivo
            const permisos = store.state.navigation.Permisos;

            // Verificamos si los permisos están vacíos
            if (Object.keys(permisos).length === 0) {
              // Ejecutar la acción loadRoutes
              store.dispatch('navigation/loadRoutes')
              .then(() => {
                console.log(store.state.navigation.Permisos);
                if (store.state.navigation.Permisos[to.meta.id]==undefined) {
                  next('/page404')  
                  // next('/page404')  
                }else{
                  next();
                }
    
              });

              // Puedes agregar una lógica adicional aquí, si es necesario
            }
            next()
          }else{  
            console.log("555"); 
            next('/seleccionarCliente')  
          }
        }
      }
    }else{      
      next()
    }
  }else{
    //Aqui poner ruta 404 un modulo con diseño animado 
    next('/page404')  
    //next('/excel')
  }

})

export default router
