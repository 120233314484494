import Vue from "vue";
import Vuex from "vuex";
import { axios2 as axios } from "@/interceptor/useApi";
import Swal from 'sweetalert2';
//import { useApi } from '@/interceptor/useApi'

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    auth: [],
    //cliente : [],
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
  },
  mutations: {
    loggedIn(state) {
      (state.loggedIn = true), localStorage.setItem("logueo", true);
    },
    auth(state, data) {
      state.auth = data;
    },
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },
    mockLogin(context) {
      context.commit("loggedIn");
    },
    registrocliente: async function ({ commit, dispatch }, datos) {
      try {
        const url = "storeCliente";
        await axios
          .post(url, datos)
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("name", response.data.name);
            localStorage.setItem("razon", response.data.razon);
            localStorage.setItem("idclient", response.data.id_cliente);
            localStorage.setItem("id", response.data.id);
            dispatch("mockLogin");
            window.location.reload();
            
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("There was an error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log("Error : ",error);
        dispatch("messageErrorAlert");
      }
    },
    
    ressetpass: async function ({ commit, dispatch }, datos) {
      try {
        //const api = useApi();

        const url = "login/ressetpass";
        const response = await axios.post(url, datos); 
        console.log(response);
        // commit("auth", response.data);
        // dispatch("mockLogin");
        
        // window.location.reload();
        return Promise.resolve(response.data);
    
      } catch (error) {
        if(error.response.status == 401){
          return Promise.resolve(error.response.data);
        }else{
          dispatch("messageErrorAlert");
        }
      }
    },
    login: async function ({ commit, dispatch }, datos) {
      try {
        //const api = useApi();

        const url = "login/authenticateSimplified";
        const response = await axios.post(url, datos);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("role", response.data.data.role);
        localStorage.setItem("id", response.data.data.userId);
        localStorage.setItem(
          "name",
          response.data.data.apellidos + " " + response.data.data.nombres
        );
        localStorage.setItem("email", response.data.data.email);
        if (response.data.data.id_cliente != "") {
          localStorage.setItem("idcliente", response.data.data.id_cliente);
        }
        commit("auth", response.data);
        dispatch("mockLogin");
        
        window.location.reload();
        return Promise.resolve(response.data);
    
      } catch (error) {
        if(error.response.status == 401){
          return Promise.resolve(error.response.data);
        }else{
          dispatch("messageErrorAlert");
        }
      }
    },
    sendRecoveryPassword: async function ({ commit, dispatch }, datos) {
      try {
        //const api = useApi();

        const url = "login/send/email";
        const response = await axios.post(url, datos);
        
        commit("auth", response.data);
        // dispatch("mockLogin");      
        // window.location.reload();
        return Promise.resolve(response.data);
    
      } catch (error) {
        if(error.response.status == 401){
          return Promise.resolve(error.response.data);
        }else{
          dispatch("messageErrorAlert");
        }
      }
    },

    updateRecoveryPassword: async function ({ commit, dispatch }, datos) {
      try {
        const url = "login/reset/password";
        const response = await axios.post(url, datos);
        
        commit("auth", response.data);
        // dispatch("mockLogin");      
        // window.location.reload();
        return Promise.resolve(response.data);
    
      } catch (error) {
        if(error.response.status == 401){
          return Promise.resolve(error.response.data);
        }else{
          dispatch("messageErrorAlert");
        }
      }
    },


    // login:async function({commit, dispatch},datos){
    //   try {
    //     const  url = "login/authenticateSimplified"
    //     await axios.post(url,datos)
    //     .then(response => {
    //       /* localStorage.setItem('token',response.data.token)
    //       localStorage.setItem('name',response.data.name)
    //       localStorage.setItem('razon',response.data.razon)
    //       localStorage.setItem('idclient',response.data.id_cliente) */
    //       localStorage.setItem('id',response.data.success.userId)
    //       localStorage.setItem('name',response.data.success.apellidos+' '+response.data.success.nombres)
    //       localStorage.setItem('email',response.data.success.email)
    //       commit('auth',response.data)
    //       dispatch('mockLogin')
    //       window.location.reload();
    //       return Promise.resolve("success");
    //       //router.push('/home')
    //       })
    //     .catch(error => {
    //       this.errorMessage = error.response.data.error;
    //       console.error(this.errorMessage);
    //       return Promise.resolve("error");
    //       //console.error("There was an error!", error);
    //     });

    //   } catch (error) {
    //   }
    // }

    // login: async function ({ commit, dispatch }, datos) {
    //   try {
    //     const url = "login/authenticateSimplified";
    //     const response = await axios.post(url, datos);
    //     /* localStorage.setItem('token',response.data.token)
    //     localStorage.setItem('name',response.data.name)
    //     localStorage.setItem('razon',response.data.razon)
    //     localStorage.setItem('idclient',response.data.id_cliente) */
    //     localStorage.setItem("id", response.data.success.userId);
    //     localStorage.setItem(
    //       "name",
    //       response.data.success.apellidos + " " + response.data.success.nombres
    //     );
    //     localStorage.setItem("email", response.data.success.email);
    //     commit("auth", response.data);
    //     dispatch("mockLogin");
    //     window.location.reload();
    //     //router.push('/home')
    //     return Promise.resolve(true);// promesa resuelta con la respuesta
    //   } catch (error) {
    //     const errorMessage = error.response?.data?.error || "Error desconocido";
    //     console.error(errorMessage);
    //     return Promise.resolve(false);// promesa rechazada con el mensaje de error
    //   }
    // }
  },
};
