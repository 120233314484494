<template>
    <div>
      <v-navigation-drawer :value="drawer" v-model="drawer" :mini-variant.sync="Mini" disable-resize-watcher app :width="drawerWidth">
        <v-img :class="{ 'img-content': true, 'small-content': Mini }" src="/images/background/user-img-background.jpg">
          <v-list-item :class="{ 'user-img px-2': true, 'small-image': Mini }">
            <v-list-item-avatar @click="navigation()">
              <v-img src="/images/background/user.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <div :class="{ 'user-details': true, 'small-details': Mini }">
            <v-list-item-title style="font-size: 15px;">{{ name }}</v-list-item-title>
            <v-list-item-title style="font-size: 14px;">{{ email }}</v-list-item-title>
          </div>
        </v-img>
        <v-divider></v-divider>
  
        <v-list dense v-for="(item_menu, index_menu) in items" :key="index_menu">
          <v-list-group :prepend-icon="item_menu.icon" @click="desplegarMenu(item_menu.id)" :value="item_menu.desplegar" v-if="item_menu.menu_submenu.length > 0">
            <template v-slot:activator>
              <v-list-item-title>{{ item_menu.menu }}</v-list-item-title>
            </template>
            <v-list-item-group v-model="item_menu.selectedItem" :mandatory="false">
              <div v-for="(item_submenu, index_submenu) in item_menu.menu_submenu" :key="index_submenu">
                <v-list-item v-if="Object.keys(item_submenu.menu_submenu).length === 0"
                  @click="insertarRuta(item_menu.id, item_submenu.id, null, item_submenu.path, 'nivel2')"
                  :class="'item_'+item_submenu.id">
                  <v-list-item-icon></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"  >{{item_submenu.description}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
  
                <v-list-group no-action sub-group v-else>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap">{{ item_submenu.description }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
  
                  <v-list-item-group v-model="item_submenu.selectedItemSub">
                    <v-list-item v-for="(itemsubsubmenu, index) in item_submenu.menu_submenu" :key="index"
                      @click="insertarRuta(item_menu.id, item_submenu.id, itemsubsubmenu.id, itemsubsubmenu.path, 'nivel3')"
                      :class="'item_'+itemsubsubmenu.id">
                      <v-list-item-icon></v-list-item-icon>
                      <v-list-item-title class="text-wrap"  >{{itemsubsubmenu.description}}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </div>
            </v-list-item-group>
          </v-list-group>
  
          <!-- Para los módulos sin submódulos -->
          <v-list-item color="indigo" v-else @click="insertarRuta(item_menu.id, null, null, item_menu.path)"
            :class="'item_'+item_menu.id">
            <v-list-item-icon>
              <v-icon>{{ item_menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ item_menu.menu }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <Bar :drawer="drawer" @drawer="drawer = $event" @revertirDespliegue="revertirDespliegue" />
    </div>
  </template>
<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import Bar from "@/components/layout/Bar.vue";
export default {
    components: {
        Bar,
    },
    data() {
        return {
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            drawer: true,
            items: [],
            drawerWidth: 290,
            activeRouteId: this.$route.meta.id   
        };
    },
    computed: {
        ...mapGetters("navigation", ["Mini", "Menu"]), 
    },
    watch: {
        // Watcher para actualizar el estado del activeRouteId cuando cambia la ruta
        '$route'(to, from) {
          console.log('aaaawwwaaa to', to.meta.id);
          console.log('aaaawwwaaa from', from.meta.id);
          // Obtener el id de la ruta anterior
          const previousRouteId = from.meta.id;
          const toRouteId = to.meta.id;

          // Eliminar las clases 'v-item--active' y 'v-list-item--active' del elemento con la clase 'item_' + previousRouteId
          const previousElement = document.querySelector('.item_' + previousRouteId);
          if (previousElement) {
            previousElement.classList.remove('v-item--active', 'v-list-item--active');
          } 
          this.$nextTick(() => {
            // Eliminar las clases 'v-item--active' y 'v-list-item--active' del elemento con la clase 'item_' + previousRouteId
            const toelement = document.querySelector('.item_' + toRouteId);
            if (toelement) {
              // Si el elemento existe, realiza las acciones necesarias
              toelement.classList.add('v-item--active', 'v-list-item--active');
            } else {
              previousElement.classList.add('v-item--active', 'v-list-item--active');
            }
          }); 
        }
    },
    methods: {
        ...mapActions("navigation", ["Minimizar", "loadRoutes", "guardarAccesosMenu"]),

         // Determina la clase activa según la id del elemento y la ruta activa
        getActiveClass(id, parentId = null, grandParentId = null) {
            if (this.activeRouteId === id) {
                // Si la ruta actual coincide con el ID del elemento, aplica la clase activa
                return 'item_'+this.activeRouteId+' v-item--active v-list-item--active';
            } else {
                // Si no es el ID actual, no aplicar la clase activa
                return 'item_'+this.activeRouteId;
            }
        },
        insertarRuta: function (id, id_submenu, id_submenu_pagina, path, nivel) {
            this.navigation();

            if (this.$route.path != path) {
                if (path == "/salirCliente") {
                    localStorage.removeItem("idcliente");
                    this.$router.go({
                        path: path
                    });
                } else {
                    this.$router.push({
                        path: path
                    });
                }

                Object.keys(this.items).forEach(key => {
                    const element = this.items[key];
                    
                    // Si SELECTEDITEM es null, se revierte el despliegue de los items diferentes al id del menú seleccionado
                    if (element.id != id) {
                        element.selectedItem = null;
                    }
                    
                    if (nivel == "nivel2") {
                        element.menu_submenu.forEach(element2 => {
                            element2.selectedItemSub = null;
                        });
                    }
                });

                let datosmenu = { id_menu: id, id_submenu: id_submenu, id_submenu_pagina: id_submenu_pagina };
                this.guardarAccesosMenu(datosmenu);
            }
            this.$forceUpdate();
        },
        desplegarMenu: function (id) {
            Object.keys(this.items).forEach(key => {
                if (this.items[key].id == id) {
                    this.items[key].desplegar = true;
                } else {
                    this.items[key].desplegar = false;
                }
            });
        },
        revertirDespliegue: function () {
            Object.keys(this.items).forEach(key => {
                this.items[key].desplegar = false;
                this.items[key].selectedItem = null;
            });
        },
        navigation() {
            if (this.Mini) {
                this.Minimizar(!this.Mini);
            }
        }
    },
    mounted: async function () {
        await this.loadRoutes(2);
        this.items = this.Menu;
        console.log("opcion 2");
        // Obtener el ID de la ruta actual al montar el componente
        const currentRouteId = this.$route.meta.id;  
        this.$nextTick(() => {
          var currentelement = document.querySelector('.item_' + currentRouteId);
          if (currentelement) {
            // Si el elemento existe, realiza las acciones necesarias
            currentelement.classList.add('v-item--active', 'v-list-item--active');
          } else {
            console.log('Elemento no encontrado');
          }
        });
      },
};
</script>
<style>
::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #c7c2c2;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #999595;
}
</style>