<template>
    <div>
        <Nuevo :dialog="dialog" :Item="itemSelected" :ItemsAplicacion="ItemsAplicacion" v-if="dialog" @closeDialog="closeDialog" />
        <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />

        <v-card>
            <v-toolbar color="secondary" dark>
                <span class="text-h6"> {{ Titulo }} </span>
                <v-spacer></v-spacer>
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn class="mx-3" fab dark small color="#2196F3" v-on="on">
                            <a target="_blank" href="https://tiresoft.pe/#aplicacion"
                                style="text-decoration:none !important; color: white;">
                                <v-icon dark>mdi-help-circle</v-icon>
                            </a>
                        </v-btn>
                    </template>
                    <span>¿Necesitas ayuda?</span>
                </v-tooltip>
            </v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn v-if="Excel" color="primary" dark small class="mb-2" @click="DescargarExcel()">
                    Descargar Tabla
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Busqueda" single-line
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-btn v-if="global._autorizacion_usuario( this.Permisos, this.$route.meta.id,['2'])" color="primary" dark class="mb-2" @click="nuevoItem()">
                    Nuevo
                </v-btn>
            </v-card-title>
            <v-data-table dense :headers="filteredCabecera" :items="Items" :items-per-page="10" :search="search"
                class="elevation-1" loading-text="Cargando... Por favor, espere"
                no-data-text="No hay datos disponibles"
                :loading="Loading"
                :footer-props="{
                   'items-per-page-text': 'Resultados por página:',
                   'page-text': '{0}-{1} de {2}' 
                 }">
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn height="22" width="22" color="blue lighten-2" fab x-small dark
                                @click="editarItem(item)" v-bind="attrs" v-on="on">
                                <v-icon x-small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import {
    mapActions,
    mapState
} from 'vuex';
import Nuevo from './nuevo.vue'
import exportFromJSON from "export-from-json"
import MensajeTransicion from "../Alerta.vue"
import global from '@/global';

export default {
    props: ["Titulo", "Cabecera", "Items", "Excel", "Crear","Loading","ItemsAplicacion"],
    components: {
        Nuevo,
        MensajeTransicion
    },
    data() {
        return {
            search: "",
            dialog: false,
            itemSelected: {},
            mensaje: "",
            verAlerta: false,
            global: global , 
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        canShowAccionColumn() {
            return ( global._autorizacion_usuario( this.Permisos, this.$route.meta.id,['3','4'])
            );
        },
        filteredCabecera() {
            return this.Cabecera.filter(col => !(col.value === 'acciones' && !this.canShowAccionColumn));
        }
    },
    methods: {
        ...mapActions("roles", ["eliminarRoles"]),
        ...mapActions('aplicacion', [ "cargarAplicacionCMB"]),


        nuevoItem() {
            this.itemSelected = {
                id: null,
                aplicacion: '',
                reencauche: '',
                prox_reencauche: '',
                ini_reencauche: '',
                ini_prox_reencauche: '',
                desg_irregular_1:'',
                desg_irregular_2:'',
                id_remanente: '',
            }
            this.cargarAplicacionCMB(); 
            this.dialog = true;
        },
        editarItem(item) {
            
            this.itemSelected = { ...item }
            this.cargarAplicacionCMB(item.aplicacion); 
            this.dialog = true;
        },
        DescargarExcel() {
            const data = this.Items;
            const nombreArchivo = 'download';
            const exportType = exportFromJSON.types.xls; //xls, json, css, html,txt 
            exportFromJSON({
                data,
                nombreArchivo,
                exportType
            })
        },
        closeDialog() {
            this.dialog = false;
        }
    }
};
</script>
