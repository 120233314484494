<template>
  <div class="pa-5">
    <v-col lg="12">
      <v-row justify="center">
        <v-col lg="12">
          <v-card>
            <v-toolbar color="secondary" dark>
              <span class="text-h6">Remanente de banda</span>
            </v-toolbar> 
            <v-container class="pa-4">
              <v-row style="margin-top: 20px">
                <div class="col-lg-4 col-sm-6 col-md-6 col-xs-12 mt-2">
                  <v-text-field
                    type="text"
                    label="Cliente"
                    v-model="Cliente.razon_social"
                    hide-details="auto"
                    readonly
                    required
                  ></v-text-field>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 col-xs-12 mt-2">
                  <v-text-field
                    type="text"
                    label="RUC"
                    v-model="Cliente.ruc"
                    hide-details="auto"
                    readonly
                    required
                  ></v-text-field>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 col-xs-12 mt-2">
                  <v-text-field
                    type="date"
                    label="Fecha inicial *"
                    v-model="Item.fecha_inicial"
                    hide-details="auto"
                    required
                  ></v-text-field>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 col-xs-12 mt-2">
                  <v-text-field
                    type="date"
                    label="Fecha final *"
                    v-model="Item.fecha_final"
                    hide-details="auto"
                    required
                  ></v-text-field>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6 col-xs-12 mt-2">
                  <v-text-field
                    type="text"
                    label="Placa "
                    v-model="Item.placa"
                    hide-details="auto"
                    required
                  ></v-text-field>
                </div>
              </v-row>
              <v-row>
                <v-col cols="1" sm="1" md="1" class="mt-2">
                  <v-btn
                    @click="validacionCampos()"
                    color="success"
                    small
                    :disabled="Item.fecha_final == null"
                  >
                    Filtrar remanente
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <vue-loaders
          v-if="loader == true"
          name="ball-beat"
          color="red"
          scale="1"
        ></vue-loaders>
      </v-row>

      <v-row justify="center" v-if="mostrarTabla == true">
        <v-col lg="12">
          <v-card>
            <v-toolbar height="60" color="#545554" dark>
              Niveles de remanente por unidad
            </v-toolbar>
            <v-container class="pa-4">
              <div class="table-responsive">
                <table class="letra-10" style="width: 100%" id="table">
                  <tr style="background-color: #6d6b6b">
                    <td
                      class="border texto-cen"
                      style="width: 20%"
                      colspan="3"
                    ></td>
                    <td
                      class="border texto-cen"
                      style="width: 5%"
                      :colspan="NivelesRemanenteUnidadPosiciones.length"
                    >
                      <strong>
                        <font color="white">Posiciones</font>
                      </strong>
                    </td>
                    <td class="border texto-cen" style="width: 5%"></td>
                    <td class="border texto-cen" style="width: 10%" colspan="2">
                      <strong>
                        <font color="white">Para reemplazar</font>
                      </strong>
                    </td>
                  </tr>
                  <tr style="background-color: #6d6b6b">
                    <td class="border texto-cen pa-1 pl-2 pr-2" style="width: 7%">
                      <strong>
                        <font color="white">Vehículo</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1 pl-5 pr-5" style="width: 9%">
                      <strong>
                        <font color="white">Fecha</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1 pl-4 pr-4" style="width: 7%">
                      <strong>
                        <font color="white">Placa</font>
                      </strong>
                    </td>
                    <td
                      class="border texto-cen pa-1"
                      style="width: 5%"
                      v-for="(item, index) in NivelesRemanenteUnidadPosiciones"
                      :key="index"
                    >
                      <strong>
                        <font color="white">{{ item }}</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Total</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 7%">
                      <strong>
                        <font color="white">Reencauchar</font>
                      </strong>
                    </td>
                    <td class="border texto-cen pa-1" style="width: 10%">
                      <strong>
                        <font color="white">Próx. a Reencauchar</font>
                      </strong>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, index) in NivelesRemanenteUnidad"
                    :key="index"
                  >
                    <td class="border texto-cen pa-1">{{ item.tipovehiculo }}</td>
                    <td class="border texto-cen pa-1">{{ item.fecha }}</td>
                    <td class="border texto-cen pa-1">{{ item.placa }}</td>

                    <td
                      v-for="(
                        item2, index2
                      ) in NivelesRemanenteUnidadPosiciones"
                      :key="index2"
                      :class="tdPintar(tdValorPosicion(index, item2),item)"
                    >
                      {{ tdValorPosicion(index, item2) }}
                    </td>

                    <td class="border texto-cen pa-1">
                      {{ item.total_neumaticos }}
                    </td>
                    <td class="border texto-cen bg-celda-amarilla pa-1">
                      {{ item.neumaticos_reencauchar }}
                    </td>
                    <td class="border texto-cen bg-celda-azul pa-1">
                      {{ item.neumaticos_proximos_reencauchar }}
                    </td>
                  </tr>
                  <tr v-if="NivelesRemanenteUnidad.length>0">
                    <td class="border texto-izq pa-2" :colspan="3 + NivelesRemanenteUnidadPosiciones.length" rowspan="2">
                      <strong>Total</strong>
                    </td>
                    <td class="border texto-cen pa-2" rowspan="2"><strong>{{ Resumen.total_neumaticos }}</strong></td>
                    <td class="border texto-cen pa-2"><strong>{{ Resumen.total_neumaticos_reencauchar }}</strong></td>
                    <td class="border texto-cen pa-2"><strong>{{ Resumen.neumaticos_proximos_reencauchar }}</strong></td>
                  </tr>
                  <tr v-if="NivelesRemanenteUnidad.length>0">
                    <td class="border texto-cen pa-2"><strong>{{ calcularPorcentaje(Resumen.total_neumaticos_reencauchar, Resumen.total_neumaticos) }}</strong></td>
                    <td class="border texto-cen pa-2"><strong>{{ calcularPorcentaje(Resumen.neumaticos_proximos_reencauchar, Resumen.total_neumaticos) }}</strong></td>
                  </tr>
                  <tr v-if="NivelesRemanenteUnidad.length == 0">
                    <td class="border texto-cen" colspan="6">
                      No hay datos para remanentes por unidad
                    </td>
                  </tr>
                </table>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      NivelesRemanenteUnidadPosiciones: 0,
      AplicacionesRemanenteUnidad: [],
      NivelesRemanenteUnidad: [],
      Resumen: {},
      Item: {
        fecha_inicial: null,
        fecha_final: null,
      },
      loader: false,
      mostrarTabla: false,
    };
  },
  computed: {
    ...mapState("cliente", ["Cliente"]),
  },
  methods: {
    ...mapActions("cliente", ["ObtenerDatosCliente"]),

    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    validacionCampos: function () {
      if (this.Item.fecha_inicial > this.Item.fecha_final) {
        this.mostrarTabla = false;
        this.$alertify.error(
          "Por favor, ingresar una fecha igual o mayor a la primera fecha seleccionada"
        );
      } else {
        this.loader = true;
        this.cargarRemanentesFiltro();
      }
    },

    cargarRemanentesFiltro: async function () {
      try {
        const url = "reporte/nivelesremanenteunidad";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          placa: this.Item.placa,
          fecha_inicial: this.Item.fecha_inicial,
          fecha_final: this.Item.fecha_final,
        };
        await axios
          .post(url, data)
          .then((response) => {
            this.NivelesRemanenteUnidadPosiciones =
              response.data.success.posiciones;
            this.NivelesRemanenteUnidad = response.data.success.datos;
            this.AplicacionesRemanenteUnidad =
              response.data.success.aplicaciones;
              this.Resumen = response.data.success.resumen;
            this.loader = false;
            this.mostrarTabla = true;
            
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            this.messageErrorAlert();
          });
      } catch (error) {
        console.log(error);
        this.messageErrorAlert();
      }
    },

    tdValorPosicion: function (index, item2) {
      return this.NivelesRemanenteUnidad[index]["nsk_minimo_" + item2];
    },

    tdPintar: function (nsk_minimo,param) {
      console.log(param ,'paraaammm');
      let rem_proximo_aru =
        parseFloat(param["rem_proximo"]) ?? 0;
      let rem_reencauche_aru =
        parseFloat(param["rem_reencauche"]) ?? 0;

      let pintar = "";
      if (nsk_minimo != "-" && nsk_minimo) {
        if (
          nsk_minimo <= rem_proximo_aru  &&
          nsk_minimo > rem_reencauche_aru
        ) {
          pintar = "border texto-cen bg-celda-azul pa-1";
        } else if (nsk_minimo <= rem_reencauche_aru) {
          pintar = "border texto-cen bg-celda-amarilla pa-1";
        } else {
          pintar = "border texto-cen pa-1";
        }
      } else {
        pintar = "border texto-cen pa-1";
      }
      return pintar;
    },

    calcularPorcentaje: function(cantidad, total){
      total = total == 0 || total == undefined || total == null ? 1 : total;
      let valor = ((cantidad/total)*100).toFixed(2);
      return valor+"%";
    }
  },
  mounted() {
    this.ObtenerDatosCliente();
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td {
  vertical-align: top;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-bottom {
  border-bottom: solid;
  border-width: 1px;
}

.border-top {
  border-top: solid;
  border-width: 1px;
}

.border-left {
  border-left: solid;
  border-width: 1px;
}

.border-right {
  border-right: solid;
  border-width: 1px;
}

.no-border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-border-left {
  border-left: none;
}

.no-border-right {
  border-right: none;
}

.texto-cen {
  text-align: center;
}

.texto-izq {
  text-align: left;
}

.texto-just {
  text-align: justify;
}

.inline {
  display: inline-block;
}

.texto-arriba {
  vertical-align: text-top;
  vertical-align: top;
}

.letra-6 {
  font-size: 6pt;
}

.letra-7 {
  font-size: 7pt;
}

.letra-8 {
  font-size: 8pt;
}

.letra-9 {
  font-size: 9pt;
}

.letra-10 {
  font-size: 10pt;
}

.letra-11 {
  font-size: 11pt;
}

.letra-12 {
  font-size: 12pt;
}

.letra-14 {
  font-size: 14pt;
}

.letra-15 {
  font-size: 15pt;
}

.letra-18 {
  font-size: 18pt;
}

.letra-25 {
  font-size: 25pt;
}

.letra-30 {
  font-size: 30pt;
}

.margin-top-20 {
  margin-top: 20px;
}

.bg-celda-azul {
  background: #91e3da;
}

.bg-celda-amarilla {
  background: #faec5c;
  /*color:#EDDF04;*/
}

.bg-celda-verde {
  background: #a8fa6f;
}

.bg-celda-roja {
  background: #f55f50;
}

#table {
  display: block; /* important */
  width: 1000px;
  overflow-x: scroll;
}
</style>