import { render, staticRenderFns } from "./tablacosto.vue?vue&type=template&id=1a01b9eb&"
import script from "./tablacosto.vue?vue&type=script&lang=js&"
export * from "./tablacosto.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports