<template>
<div>
    <v-dialog v-model="dialog" max-width="600px" @click:outside="cerrarDialogo(false)">
        <v-card>
            <v-card-title>
                <span v-if="Item.id == null" class="text-h5">Nueva Aplicación</span>
                <span v-else class="text-h5">Editar Aplicación</span>
                <v-spacer></v-spacer>
                <span v-if="Item.id != null">ID: {{Item.id}}</span>
            </v-card-title>
            <v-card-text>
                <!-- <FormularioDinamico :campos="campos"/> -->
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-autocomplete 
                                :items="ItemsAplicacion" 
                                v-model="Item.aplicacion" 
                                item-text="aplicacion" 
                                item-value="id" 
                                label="Seleccionar Aplicación *" 
                                :rules="[(v) => !!v || 'Aplicacion es requerida']" 
                                dense>
                                <template v-slot:append-outer>
                                    <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon color="success" v-text="'mdi-plus-circle'" @click="abrirdialogapli"></v-icon>
                                    </v-slide-x-reverse-transition>
                                </template>
                            </v-autocomplete>
                            <!-- <v-text-field label="Aplicación *" hide-details="auto" v-model="Item.aplicacion" :rules="[(v) => !!v || 'Aplicación requerida']"></v-text-field> -->
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" @input="validarreglas" label="Inicio de Reencauche *" hide-details="auto" v-model="Item.ini_reencauche" :rules="[(v) => !!v || 'Campo requerido', v => /^[0-9]+(\.[0-9]+)?$/.test(v) || 'No se permiten numeros negativos']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" label="Limite de Reencauche *" hide-details="auto" v-model="Item.reencauche" :rules="[(v) => !!v || 'Campo requerido', v => /^[0-9]+(\.[0-9]+)?$/.test(v) || 'No se permiten numeros negativos',(v) => v > parseFloat(Item.ini_reencauche) || 'Debe ser mayor que el Inicio de reencauche']" @input="dariniproxreen" @change="validarreglas" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" @input="validarreglas" label="Inicio de Próximo a Reencauchar *" hide-details="auto" v-model="Item.ini_prox_reencauche" required :rules="[(v) => !!v || 'Campo requerido', v => /^[0-9]+(\.[0-9]+)?$/.test(v) || 'No se permiten numeros negativos',(v) => v > parseFloat(Item.reencauche) || 'Debe ser mayor que el Limite de reencauche']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" @input="validarreglas" label="Limite de Próximo a Reencauchar *" hide-details="auto" v-model="Item.prox_reencauche" required :rules="[(v) => !!v || 'Campo requerido', v => /^[0-9]+(\.[0-9]+)?$/.test(v) || 'No se permiten numeros negativos',(v) => v > parseFloat(Item.ini_prox_reencauche) || 'Debe ser mayor que el Inicio del Próximo reencauche']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number" @input="darvalordesg_irre_2"  label="Desgaste Irregular 1 *" hide-details="auto" v-model="Item.desg_irregular_1" required :rules="[(v) => !!v || 'Campo requerido']"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field type="number"  label="Desgaste Irregular 2 *" hide-details="auto" v-model="Item.desg_irregular_2" required :rules="[(v) => !!v || 'Campo requerido']"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <small>*indica los campos requeridos</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="cerrarDialogo(false)">
                    Cerrar
                </v-btn>
                <v-btn color="info" @click="agregar()" :loading="loading" :disabled="!valid">
                    Guardar
                </v-btn>
            </v-card-actions>
            <MensajeTransicion color="#FA694A" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
        </v-card>
    </v-dialog>
    <v-row justify="center" v-if="modal_aplicacion">
        <v-dialog v-model="dialogaplicacion" max-width="300px" @click:outside="cerrardialogapli">
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="formaplicacion" v-model="valid">
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field label="Nombre de la Aplicacion *" required v-model="aplicacion" :rules="[(v) => !!v || 'Campo requerido']"></v-text-field>
                                </v-col>
                                <small v-if="mostrarLabelDuplicado">
                                    <font color="red">Aplicacion duplicada</font>
                                </small>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small v-if="mostrarLabelDuplicado == false">*Indicador de campo requerido</small>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!valid" class="mt-4" style="width: 100%" small color="#009688" @click="addAplicacion()">
                        <font color="white">Agregar</font>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MensajeTransicion from "../Alerta.vue"
import { notif } from "@/plugins/notyf"
import global from '@/global'

export default {
    props: ['dialog', 'Item','ItemsAplicacion'],
    components: {
        MensajeTransicion
    },
    data() {
        return {
            valid: false,
            loading: false,
            mensaje: "",
            verAlerta: false,
            modal_aplicacion:false,
            dialogaplicacion:false,
            aplicacion:'',
            mostrarLabelDuplicado:false,
            aplicacion_ofi:'',
        };
    },
    computed: {
        ...mapState("navigation", ["Permisos"]),
        ...mapState("aplicacion", ["Resultados"]),
    },
    mounted(){
        this.aplicacion_ofi=this.Item.aplicacion;
    },
    methods: {
        ...mapActions('aplicacion', ['agregarAplicacion', "cargarAplicacion" ,"agregarAplicacionNombre",'cargarAplicacionCMB']),
        async agregar() {
            this.loading = true;
            if (this.Item.id == null || this.Item.id == '') {
                await this.agregarAplicacion(this.Item);
                this.cerrarDialogo();
            } else {
                await this.agregarAplicacion(this.Item);
                this.cerrarDialogo();
            }
        },
        cerrarDialogo(valor = true) {
            if(valor == true){
                this.cargarAplicacion();
                notif.success('Operación realizada con éxito');
            }
            this.loading = false;
            this.$emit('closeDialog');
        },
        abrirdialogapli(){
            this.modal_aplicacion=true;
            this.dialogaplicacion=true;
        },
        cerrardialogapli(){
            this.modal_aplicacion=false;
            this.dialogaplicacion=false;
            this.aplicacion='';
        },
        addAplicacion(){
            if (this.$refs.formaplicacion.validate()) {
                this.agregarAplicacionNombre({aplicacion:this.aplicacion})
                console.log(this.Resultados,'resultados');
                if (this.Resultados=='error') {
                    notif.error('Aplicacion Duplicada');
                    this.mostrarLabelDuplicado=true;
                }else{
                    if (this.Item.id==null) {
                        this.cargarAplicacionCMB();
                    }else{
                        this.cargarAplicacionCMB(this.aplicacion_ofi);
                    }
                    this.cerrardialogapli();
                }
            }
        },
        dariniproxreen(e){ 
                this.Item.ini_prox_reencauche=parseInt(e)+1; 
        },
        validarreglas(){
            this.$refs.form.validate();
        },
        darvalordesg_irre_2(e){  
                this.Item.desg_irregular_2=parseFloat(e)*-1; 
        }
    }
}
</script>
