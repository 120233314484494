<template>
  <v-layout justify-center align-center fill-height
    style="background-image: url(/images/background/fondo.jpg); width: 100% !important;min-height: 100vh; background-repeat: no-repeat; background-position: center; background-size: cover; position: relative; z-index: 1; margin: 0 !important;">

    <v-container fluid fill-height text-center>
      <v-layout align-center justify-center pa-10>
        <v-flex xl3 lg4 md6 sm6 xs12>
          <v-card class="elevation-12 rounded-xl pa-4">
            <img style="width: 170px;" src="/images/background/logo2.png">
            <br>
            <!-- <p> {{
              isRecovery ? stateObj.recovery.name : stateObj.login.name
            }}
            </p> -->

            <v-card-text>
              <v-form ref="form" lazy-validation @submit.prevent="isRecovery ? recoveryPassword() : loginHome()">
                <v-text-field v-if="isRegister" v-model="Empresa" label="Nombre de la Empresa" hide-details="auto"
                  prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-if="isRegister" v-model="Nombre" label="Nombre del Cliente" hide-details="auto"
                  prepend-icon="mdi-account"></v-text-field>
                <v-text-field v-if="isRegister" v-model="Telefono" label="Telefono" hide-details="auto"
                  prepend-icon="mdi-phone"></v-text-field>

                <v-text-field v-if="isRegister && !isRecovery" prepend-icon="mdi-email" v-model="email"
                  :rules="emailRules" name="email" label="Email" type="text" placeholder="Email" required
                  hide-details="auto"></v-text-field>
                <v-text-field v-if="!isRegister && !isRecovery" prepend-icon="mdi-account" v-model="email"
                  :rules="emailRules" name="email" label="Email" type="text" placeholder="Email" required
                  hide-details="auto"></v-text-field>

                <v-text-field v-if="!isRecovery" class="mt-5" v-model="password" prepend-icon="mdi-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                  :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña" hint="Al menos 6 caracteres"
                  counter @click:append="show1 = !show1"></v-text-field>

                <v-text-field v-if="isRecovery" prepend-icon="mdi-email" v-model="email" :rules="emailRules"
                  name="email" label="Email" type="text" placeholder="Email" required
                  hide-details="auto"></v-text-field>
                <p v-if="isRecovery" class="mb-5" style="text-align: center; font-size: 12px; color: cornflowerblue;">
                  Ingresa tu correo electrónico para recuperar tu contraseña.</p>

                <v-text-field v-if="isRegister" prepend-icon="mdi-lock" v-model="confirmPassword" name="confirmPassword"
                  label="Confirmar Contraseña" type="password" placeholder="Confirmar Contraseña"
                  required></v-text-field>

                <v-checkbox v-if="isRegister" v-model="checkbox">
                  <template v-slot:label>
                    <div>
                      He leído y acepto los
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a target="_blank" href="https://tiresoft.pe" @click.stop v-on="on">
                            Terminos de uso
                          </a>
                        </template>
                        Ver Terminos de Uso
                      </v-tooltip>
                      ***
                    </div>
                  </template>
                </v-checkbox>
                <div class="red--text text--lighten-1 text-body-2 mb-4">
                  {{ errorMessage }}
                </div>
                <v-btn :loading="loading" :disabled="!valid" dark type="submit" class="mb-5" color="error"
                  value="log in" submit>{{
                    isRecovery
                      ? stateObj.recovery.namebtn
                      : stateObj.login.namebtn
                  }}</v-btn>
                <!-- <v-btn :loading="loading" :disabled="!valid" dark type="submit" class="mb-5" color="error" value="log in"
                  submit>{{
                    isRegister
                    ? stateObj.register.namebtn
                    : isResetPass
                    ? stateObj.resetpass.namebtn
                    : stateObj.login.namebtn
                  }}</v-btn> -->

                <!-- <div class="grey--text mt-4" color="blue accent-4" v-on:click="isRegister = !isRegister;">
                        {{toggleMessage}}  
                      </div> -->
                <div v-if="!isRecovery" class="pa-2">
                  ¿Olvidaste tu
                  <!-- <a color="blue accent-4" @click="insertarRuta('/resetpassword')"> -->
                  <a color="blue accent-4" @click="cambioRecovery()">
                    contraseña</a>?

                  <br>
                  <!-- ¿Aun no tienes una cuenta?
                  <a color="blue accent-4" @click="CambioPantalla()">
                    Registrate
                  </a>
                  <br> -->
                </div>
                <!-- <div v-else class="pa-2">
                  <a color="blue accent-4" @click="CambioPantalla()">
                    ¿Ya tienes una cuenta?
                  </a>
                </div> -->
                <div v-else class="pa-2">
                  ¿Ya tienes una cuenta?
                  <a color="blue accent-4" @click="cambioRecovery()">
                    Iniciar sesión
                  </a>
                </div>
              </v-form>
            </v-card-text>
            <MensajeTransicion :color="mensajeColor" :text="mensaje" v-if="verAlerta" :top="true" :bottom="false" />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-form ref="form" lazy-validation @submit.prevent="updatePassword()">
        <v-card>
          <v-card-title>
            <span class="text-h6">Actualizar Contraseña </span>
          </v-card-title>
          <v-card-text>
            <p class="mb-5" style="text-align: center;font-size: 12px;color: white; background-color: cornflowerblue; border-radius: 5px;">
              Por favor, revise su buzón de correo o la carpeta de spam para encontrar el token de recuperación.
            </p>
            <v-text-field label="Email" hide-details="auto" prepend-icon="mdi-email" v-model="email"
              readonly></v-text-field>

            <v-text-field name="r_token" v-model="r_token" label="Token" hide-details="auto"
              prepend-icon="mdi-key"></v-text-field>
            <br>
            <v-text-field name="r_password" v-model="r_password" prepend-icon="mdi-lock"
              :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" label="Contraseña"
              hint="Al menos 6 caracteres" counter @click:append="show1 = !show1"></v-text-field>

            <v-text-field name="r_confirmPassword" v-model="r_confirmPassword" prepend-icon="mdi-lock"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'" label="Repitar Contraseña" hint="Al menos 6 caracteres" counter
              @click:append="show1 = !show1"></v-text-field>

            <div class="red--text text--lighten-1 text-body-2 mb-4 text-center"> {{ errorMessage }}</div>


          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="cerrarDialogo()">
              Cerrar
            </v-btn>
            <v-btn :loading="loading" :disabled="!valid" dark type="submit" color="info" submit>Guardar</v-btn>
          </v-card-actions>

        </v-card>
      </v-form>

    </v-dialog>

  </v-layout>



</template>
<script>
import { mapActions } from 'vuex';
import MensajeTransicion from '@/components/Alerta.vue'
import Swal from 'sweetalert2';
import router from '@/router';

export default {
  name: "App",
  components: {
    MensajeTransicion
  },
  data() {
    return {
      loading: false,
      valid: true,
      checkbox: true,
      username: "",
      confirmPassword: "",
      isRegister: false,
      isRecovery: false,
      errorMessage: "",
      stateObj: {
        register: {
          name: "Registrar Usuario",
          namebtn: "Registrar",
          message: "¿Ya tienes una cuenta?",
        },
        login: {
          name: "",
          namebtn: "Ingresar",
          message: "¿Ahún no tienes una cuenta ? Registrate",
        },
        recovery: {
          name: "Recuperar Contraseña",
          namebtn: "Enviar",
          message: "¿Ya tienes una cuenta?",
        },
      },
      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail es requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail no es valido',
      ],
      show1: false,
      password: '',
      rules: {
        required: v => !!v || 'Requerido.',
        min: v => v.length >= 6 || 'Min 6 characters'
      },
      Empresa:'',
      Nombre:'',
      Telefono:'',
      verAlerta: false,
      mensaje: "",
      dialog: false,
      r_token: "",
      r_password: "",
      r_confirmPassword: "",
      mensajeColor: "#FA694A",
    }
  },
  created() {
  },
  methods: {
    insertarRuta(param) {
      router.push(param);
    },
    async ressetpass(){
      if (this.$refs.form.validate()) {
        this.loading = true;

        const data = { email: this.email };
        const response = await this.ressetpass(data);

        if (!response.status) {
          this.loading = false;
          this.mensaje = response.message;
          this.verAlerta = true;
          setTimeout(() => [(this.verAlerta = false)], 4000);
        }
      }
    },
    register() {
      if (this.password == this.confirmPassword) {
        if (this.$refs.form.validate()) {
          const data = { Empresa: this.Empresa, name: this.Nombre, Telefono: this.Telefono, email: this.email, password: this.password };
          this.registrocliente(data)
        }
      } else {
        this.errorMessage = "Las contraseñas no coinciden";
      }
    },
    async recoveryPassword() {

      if (this.$refs.form.validate()) {

        this.loading = true;
        const data = { email: this.email };
        const response = await this.sendRecoveryPassword(data);
        if (response.status) {
          this.loading = false;
          this.mensaje = response.message
          this.verAlerta = true;
          setTimeout(() => [(this.verAlerta = false)], 4000);
          this.dialog = true;
          this.errorMessage = "";
          this.mensajeColor = "#4CAF50";
        } else {
          this.loading = false;
          this.errorMessage = "Upps Algo Paso!";
        }
      } else {
        this.errorMessage = "Por favor ingrese su correo!";
      }
    },
    async loginHome() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const data = { email: this.email, password: this.password };
        const response = await this.login(data);

        if (!response.status) {
          this.loading = false;
          this.mensaje = response.message;
          this.verAlerta = true;
          setTimeout(() => [(this.verAlerta = false)], 4000);
          this.mensajeColor = "#FA694A";
        }
      }
    },
    CambioPantalla(param) {
      if (param==2) {
        this.isRegister=false;
        this.isResetPass=true;
      }else if(param==1){
        this.isRegister=true;
        this.isResetPass=false;
      }else{
        this.isRegister=false;
        this.isResetPass=false;
      } 
      this.$refs.form.resetValidation()
    },
    cambioRecovery() {
      this.isRecovery = !this.isRecovery
    },
    async updatePassword() {
      this.loading = true;

      if (this.r_password == this.r_confirmPassword) {
        const data = { email: this.email, token_password: this.r_token, new_password: this.r_password };
        const response = await this.updateRecoveryPassword(data);

        if (response.status) {
          this.loading = false;
          this.mensaje = response.message;
          this.verAlerta = true;
          setTimeout(() => {
            this.verAlerta = false;
          }, 4000); // Espera a que se muestre el mensaje antes de redirigir
          this.dialog = false;
          this.errorMessage = "";
          this.isRegister = false;
          this.isRecovery = false;
          this.mensajeColor = "#4CAF50";
        } else {
          this.loading = false;
          this.errorMessage = "¡Upps! Algo pasó.";
        }
      } else {
        this.loading = false;
        this.errorMessage = "Las contraseñas no coinciden";
      }
    },
    cerrarDialogo(valor = false) {
      this.dialog = false;
    },
    ...mapActions('login', ['mockLogin', 'registrocliente', 'login', 'sendRecoveryPassword', 'updateRecoveryPassword'])
  },
  computed: {
    toggleMessage: function () {
      return this.isRegister
        ? this.stateObj.register.message
        : this.stateObj.login.message;
    },
  },
};
</script>
